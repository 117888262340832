// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { VendorRow } from '../typings';

const data = [
  {
    "hostname": "rawlings-bat.cdev.orangelv.com",
    "environment": "development",
    "sportId": "baseball",
    "vendorId": "rawlings",
    "orderMethod": "client-cart",
    "addToCartUrl": "https://development-jts-teamsports.demandware.net/s/rawlings-consolidated/customglove",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://development-jts-teamsports.demandware.net/",
    "returnToSiteName": "rawlings.com"
  },
  {
    "hostname": "rawlings-bats-staging.cdev.orangelv.com",
    "environment": "staging",
    "sportId": "baseball",
    "vendorId": "rawlings",
    "orderMethod": "client-cart",
    "addToCartUrl": "https://staging-jts-teamsports.demandware.net/s/rawlings-consolidated/customglove",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://staging-jts-teamsports.demandware.net/",
    "returnToSiteName": "rawlings.com"
  },
  {
    "hostname": "rawlings-bats-prod.cdev.orangelv.com",
    "environment": "production",
    "sportId": "baseball",
    "vendorId": "rawlings",
    "orderMethod": "client-cart",
    "addToCartUrl": "https://www.rawlings.com/customglove",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://www.rawlings.com",
    "returnToSiteName": "rawlings.com"
  },
  {
    "hostname": "staging.woodbats.custom.rawlings.com",
    "environment": "staging",
    "sportId": "baseball",
    "vendorId": "rawlings",
    "orderMethod": "client-cart",
    "addToCartUrl": "https://staging-jts-teamsports.demandware.net/s/rawlings-consolidated/customglove",
    "twitterHandle": "rawlingssports",
    "returnToSiteUrl": "https://staging-jts-teamsports.demandware.net/",
    "returnToSiteName": "rawlings.com"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<VendorRow[]>> = data;

export type Vendor = typeof typed[number];

export const VENDOR_INDEX_KEY = "hostname";
export type VendorIndexKey = "hostname";
export type VendorHostname = Vendor["hostname"];

let i = 0;
export const VENDOR_DICT = {
  "rawlings-bat.cdev.orangelv.com": typed[i++],
  "rawlings-bats-staging.cdev.orangelv.com": typed[i++],
  "rawlings-bats-prod.cdev.orangelv.com": typed[i++],
  "staging.woodbats.custom.rawlings.com": typed[i++]
} as const;

export { typed as VENDORS };
