// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { StripeColorRow } from '../typings';

const data = [
  {
    "id": "lightBlue",
    "name": "Light Blue",
    "subsets": {
      "stripeSize": {
        "0_25": false
      }
    },
    "props": {
      "hex": "#2897d6",
      "surfaceMaterialId": "vinylSticker"
    }
  },
  {
    "id": "black",
    "name": "Black",
    "subsets": {
      "stripeSize": {
        "0_25": true
      }
    },
    "props": {
      "hex": "#231f20",
      "surfaceMaterialId": "vinylSticker"
    }
  },
  {
    "id": "pink",
    "name": "Pink",
    "subsets": {
      "stripeSize": {
        "0_25": false
      }
    },
    "props": {
      "hex": "#f0008c",
      "surfaceMaterialId": "vinylSticker"
    }
  },
  {
    "id": "green",
    "name": "Green",
    "subsets": {
      "stripeSize": {
        "0_25": false
      }
    },
    "props": {
      "hex": "#008648",
      "surfaceMaterialId": "vinylSticker"
    }
  },
  {
    "id": "navy",
    "name": "Navy",
    "subsets": {
      "stripeSize": {
        "0_25": false
      }
    },
    "props": {
      "hex": "#003065",
      "surfaceMaterialId": "vinylSticker"
    }
  },
  {
    "id": "orange",
    "name": "Orange",
    "subsets": {
      "stripeSize": {
        "0_25": false
      }
    },
    "props": {
      "hex": "#f77900",
      "surfaceMaterialId": "vinylSticker"
    }
  },
  {
    "id": "purple",
    "name": "Purple",
    "subsets": {
      "stripeSize": {
        "0_25": false
      }
    },
    "props": {
      "hex": "#4e2c80",
      "surfaceMaterialId": "vinylSticker"
    }
  },
  {
    "id": "royal",
    "name": "Royal",
    "subsets": {
      "stripeSize": {
        "0_25": false
      }
    },
    "props": {
      "hex": "#005ba1",
      "surfaceMaterialId": "vinylSticker"
    }
  },
  {
    "id": "scarlet",
    "name": "Scarlet",
    "subsets": {
      "stripeSize": {
        "0_25": false
      }
    },
    "props": {
      "hex": "#cb3029",
      "surfaceMaterialId": "vinylSticker"
    }
  },
  {
    "id": "silver",
    "name": "Silver",
    "subsets": {
      "stripeSize": {
        "0_25": false
      }
    },
    "props": {
      "hex": "#9b9b9b",
      "surfaceMaterialId": "vinylSticker"
    }
  },
  {
    "id": "white",
    "name": "White",
    "subsets": {
      "stripeSize": {
        "0_25": true
      }
    },
    "props": {
      "hex": "#ffffff",
      "surfaceMaterialId": "vinylSticker"
    }
  },
  {
    "id": "goldFoil",
    "name": "Gold",
    "subsets": {
      "stripeSize": {
        "0_25": true
      }
    },
    "props": {
      "hex": "#ffe677",
      "surfaceMaterialId": "foilSticker"
    }
  },
  {
    "id": "silverFoil",
    "name": "Silver",
    "subsets": {
      "stripeSize": {
        "0_25": true
      }
    },
    "props": {
      "hex": "#fcfcfc",
      "surfaceMaterialId": "foilSticker"
    }
  },
  {
    "id": "hologram",
    "name": "Hologram",
    "subsets": {
      "stripeSize": {
        "0_25": true
      }
    },
    "props": {
      "surfaceMaterialId": "foilSticker",
      "textureId": "hologram"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<StripeColorRow[]>> = data;

export type StripeColor = typeof typed[number];

export const STRIPE_COLOR_INDEX_KEY = "id";
export type StripeColorIndexKey = "id";
export type StripeColorId = StripeColor["id"];

let i = 0;
export const STRIPE_COLOR_DICT = {
  "lightBlue": typed[i++],
  "black": typed[i++],
  "pink": typed[i++],
  "green": typed[i++],
  "navy": typed[i++],
  "orange": typed[i++],
  "purple": typed[i++],
  "royal": typed[i++],
  "scarlet": typed[i++],
  "silver": typed[i++],
  "white": typed[i++],
  "goldFoil": typed[i++],
  "silverFoil": typed[i++],
  "hologram": typed[i++]
} as const;

export { typed as STRIPE_COLOR };
