import { Mesh } from '@babylonjs/core'

import createEngine from '~p/client/render3d/createEngine'
import createScene from '~p/client/render3d/createScene'
import createCamera from '~p/client/render3d/createCamera'
import { createBatMaterial, createGripTapeMaterial } from './materials'

const createSceneContext = (canvas) => {
  const engine = createEngine(canvas)

  const scene = createScene(engine)

  const camera = createCamera(scene)

  const batMaterial = createBatMaterial(scene)
  const gripTapeMaterial = createGripTapeMaterial(scene)

  const rotationMesh = new Mesh('rotation', scene)
  const positionMesh = new Mesh('position', scene)
  positionMesh.parent = rotationMesh

  return {
    engine,
    scene,
    camera,
    materials: {
      bat: batMaterial,
      gripTape: gripTapeMaterial,
    },
    rotationMesh,
    positionMesh,
  }
}

export default createSceneContext
