// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { LengthRow } from '../typings';

const data = [
  {
    "110": false,
    "151": false,
    "243": false,
    "id": "17",
    "name": "17\"",
    "MM13": false,
    "BH3": false,
    "OA1": false,
    "271ML": false,
    "I13M": false,
    "MT27": false,
    "RA13": false,
    "AP5": false,
    "CS5": false,
    "MT456": false,
    "Y62": false
  },
  {
    "110": false,
    "151": false,
    "243": false,
    "id": "27",
    "name": "27\"",
    "MM13": false,
    "BH3": false,
    "OA1": false,
    "271ML": false,
    "I13M": false,
    "MT27": false,
    "RA13": false,
    "AP5": false,
    "CS5": false,
    "MT456": false,
    "Y62": false
  },
  {
    "110": false,
    "151": false,
    "243": false,
    "id": "28",
    "name": "28\"",
    "MM13": false,
    "BH3": false,
    "OA1": false,
    "271ML": false,
    "I13M": false,
    "MT27": false,
    "RA13": false,
    "AP5": false,
    "CS5": false,
    "MT456": false,
    "Y62": false
  },
  {
    "110": false,
    "151": false,
    "243": false,
    "id": "29",
    "name": "29\"",
    "MM13": true,
    "BH3": true,
    "OA1": false,
    "271ML": false,
    "I13M": false,
    "MT27": false,
    "RA13": false,
    "AP5": false,
    "CS5": false,
    "MT456": false,
    "Y62": false
  },
  {
    "110": false,
    "151": false,
    "243": false,
    "id": "30",
    "name": "30\"",
    "MM13": true,
    "BH3": true,
    "OA1": false,
    "271ML": false,
    "I13M": false,
    "MT27": false,
    "RA13": false,
    "AP5": false,
    "CS5": false,
    "MT456": false,
    "Y62": false
  },
  {
    "110": true,
    "151": false,
    "243": true,
    "id": "31",
    "name": "31\"",
    "MM13": true,
    "BH3": true,
    "OA1": true,
    "271ML": true,
    "I13M": true,
    "MT27": true,
    "RA13": true,
    "AP5": true,
    "CS5": true,
    "MT456": true,
    "Y62": false
  },
  {
    "110": false,
    "151": false,
    "243": false,
    "id": "31_5",
    "name": "31.5\"",
    "MM13": false,
    "BH3": false,
    "OA1": false,
    "271ML": false,
    "I13M": false,
    "MT27": false,
    "RA13": false,
    "AP5": false,
    "CS5": false,
    "MT456": false,
    "Y62": false
  },
  {
    "110": true,
    "151": false,
    "243": true,
    "id": "32",
    "name": "32\"",
    "MM13": true,
    "BH3": true,
    "OA1": true,
    "271ML": true,
    "I13M": true,
    "MT27": true,
    "RA13": true,
    "AP5": true,
    "CS5": true,
    "MT456": true,
    "Y62": false
  },
  {
    "110": true,
    "151": false,
    "243": true,
    "id": "32_5",
    "name": "32.5\"",
    "MM13": true,
    "BH3": true,
    "OA1": true,
    "271ML": true,
    "I13M": true,
    "MT27": true,
    "RA13": true,
    "AP5": true,
    "CS5": true,
    "MT456": true,
    "Y62": false
  },
  {
    "110": true,
    "151": false,
    "243": true,
    "id": "33",
    "name": "33\"",
    "MM13": true,
    "BH3": true,
    "OA1": true,
    "271ML": true,
    "I13M": true,
    "MT27": true,
    "RA13": true,
    "AP5": true,
    "CS5": true,
    "MT456": true,
    "Y62": false
  },
  {
    "110": true,
    "151": false,
    "243": true,
    "id": "33_5",
    "name": "33.5\"",
    "MM13": true,
    "BH3": true,
    "OA1": true,
    "271ML": true,
    "I13M": true,
    "MT27": true,
    "RA13": true,
    "AP5": true,
    "CS5": true,
    "MT456": true,
    "Y62": false
  },
  {
    "110": true,
    "151": false,
    "243": true,
    "id": "34",
    "name": "34\"",
    "MM13": true,
    "BH3": false,
    "OA1": true,
    "271ML": true,
    "I13M": true,
    "MT27": true,
    "RA13": true,
    "AP5": true,
    "CS5": true,
    "MT456": true,
    "Y62": false
  },
  {
    "110": false,
    "151": false,
    "243": false,
    "id": "34_5",
    "name": "34.5\"",
    "MM13": false,
    "BH3": true,
    "OA1": false,
    "271ML": false,
    "I13M": false,
    "MT27": false,
    "RA13": false,
    "AP5": false,
    "CS5": false,
    "MT456": false,
    "Y62": false
  },
  {
    "110": false,
    "151": false,
    "243": false,
    "id": "35",
    "name": "35\"",
    "MM13": false,
    "BH3": false,
    "OA1": false,
    "271ML": false,
    "I13M": false,
    "MT27": false,
    "RA13": false,
    "AP5": false,
    "CS5": false,
    "MT456": false,
    "Y62": false
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<LengthRow[]>> = data;

export type Length = typeof typed[number];

export const LENGTH_INDEX_KEY = "id";
export type LengthIndexKey = "id";
export type LengthId = Length["id"];

let i = 0;
export const LENGTH_DICT = {
  "17": typed[i++],
  "27": typed[i++],
  "28": typed[i++],
  "29": typed[i++],
  "30": typed[i++],
  "31": typed[i++],
  "31_5": typed[i++],
  "32": typed[i++],
  "32_5": typed[i++],
  "33": typed[i++],
  "33_5": typed[i++],
  "34": typed[i++],
  "34_5": typed[i++],
  "35": typed[i++]
} as const;

export { typed as LENGTH };
