// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ModelAttributeRow } from '../typings';

const data = [
  {
    "id": "sweetSpot",
    "name": "Sweet Spot",
    "rangeLabels": [
      "Small",
      "Large"
    ]
  },
  {
    "id": "density",
    "name": "Density",
    "rangeLabels": [
      "Low",
      "High"
    ]
  },
  {
    "id": "swingWeight",
    "name": "Swing Weight",
    "rangeLabels": [
      "Heavy",
      "Light"
    ]
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ModelAttributeRow[]>> = data;

export type ModelAttribute = typeof typed[number];

export const MODEL_ATTRIBUTE_INDEX_KEY = "id";
export type ModelAttributeIndexKey = "id";
export type ModelAttributeId = ModelAttribute["id"];

let i = 0;
export const MODEL_ATTRIBUTE_DICT = {
  "sweetSpot": typed[i++],
  "density": typed[i++],
  "swingWeight": typed[i++]
} as const;

export { typed as MODEL_ATTRIBUTES };
