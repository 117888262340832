import { MODEL_DICT, LENGTH_DICT, WOOD_SPECIE_DICT } from './sheets'

const PRODUCT_TITLE = `Custom Rawlings Baseball Bat`

const getIndexTitle = () => 'Rawlings Baseball Bat Builder'

const getIndexDescription = () =>
  'Build your own baseball bat in a photorealistic customizer.'

const getSkuDescription = () =>
  'Build your own baseball bat based on this template.'

const getRecipeDescription = () =>
  'Order this baseball bat or build your own based on it.'

const getVia = (config) => {
  if (config.hideVia || config.vendorName == null) {
    return ''
  }
  return ` via ${config.vendorName}`
}

const getSkuValues = (sku) => {
  const [modelId, woodSpeciesId, lengthId] = sku.split('-')

  const result = {
    woodSpecies: WOOD_SPECIE_DICT[woodSpeciesId],
    model: MODEL_DICT[modelId],
    length: LENGTH_DICT[lengthId],
  }

  if (
    result.model == null ||
    result.woodSpecies == null ||
    result.length == null
  ) {
    throw new Error(`SKU is not valid: "${sku}" - ${JSON.stringify(result)}`)
  }

  return result
}

const getSkuLabel = (model, knobStyle) =>
  `${model.id}${knobStyle?.props?.skuSuffix ?? ''}`

const getSkuTitle = (config, params) => {
  const sku = params[0]
  const { model } = getSkuValues(sku)

  return `${model.name} - ${PRODUCT_TITLE} Template${getVia(config)}`
}

const getRecipeTitle = (config, recipe, id, hasChanges) =>
  `Design #${id}${hasChanges ? ' with changes' : ''} - ${PRODUCT_TITLE}${getVia(
    config,
  )}`

const getStaticTags = () => ''

const garmentSizes = []

export {
  getIndexTitle,
  getIndexDescription,
  getSkuValues,
  getSkuTitle,
  getSkuLabel,
  getSkuDescription,
  getRecipeTitle,
  getRecipeDescription,
  getStaticTags,
  garmentSizes,
}
