import { isEqual } from '@technically/lodash'
import React from 'react'
// As stated in BabylonJS 101, this polyfill is for pointer events on iOS and other browsers.
// https://doc.babylonjs.com/babylon101/first
import 'handjs'

import SceneLoader from '../SceneLoader'
import updateCamera from '../updateCamera'
import updateProjectionMatrix from '../updateProjectionMatrix'
import generatePreviews from '../generatePreviews'

class RenderEngine extends React.Component {
  componentDidMount() {
    this.props.setPreviewGeneratorWarm(generatePreviews)

    this.sceneContext = this.props.createSceneContext(this.canvas)

    this.engine = this.sceneContext.engine
    this.scene = this.sceneContext.scene
    this.camera = this.sceneContext.camera

    this.assetsLoader = new SceneLoader(this.sceneContext.scene)

    this.resizeListener = window.addEventListener('resize', () => {
      // Hack with beta diff for getting the scene to resize correctly.
      this.camera.beta += this.cameraBetaDiff
      this.cameraBetaDiff = -this.cameraBetaDiff

      this.engine.resize()
      this.scene.render()
    })

    document.addEventListener('visibilitychange', this.visibilityChangeHandler)

    this.startLoop()

    this.componentDidRender(null, this.props)
  }

  componentDidUpdate(prevProps) {
    this.componentDidRender(prevProps, this.props)
  }

  componentWillUnmount() {
    this.props.setPreviewGeneratorWarm(undefined)

    this.assetsLoader.dispose()

    this.engine.dispose()

    window.removeEventListener('resize', this.resizeListener)
    document.removeEventListener(
      'visibilitychange',
      this.visibilityChangeHandler,
    )
  }

  componentDidRender(prevProps, nextProps) {
    // Canvas size may change between renders.
    this.engine.resize()
    updateProjectionMatrix(this.camera, this.engine, nextProps.cameraDef)
    this.scene.render()

    const isSceneDefEqual =
      prevProps && isEqual(prevProps.sceneDef, nextProps.sceneDef)
    const isCameraDefEqual =
      prevProps && isEqual(prevProps.cameraDef, nextProps.cameraDef)

    if (isSceneDefEqual && isCameraDefEqual) {
      return
    }

    if (!isCameraDefEqual) {
      updateCamera(this.camera, nextProps.cameraDef)

      if (nextProps.cameraDef.detachControl) {
        this.camera.detachControl(this.canvas)
      } else if (!this.camera.inputs.attachedElement) {
        this.camera.attachControl(this.canvas, null, false)
        const inputs = this.camera.inputs.attached
        const pointerInputs = inputs.pointers
        if (pointerInputs) {
          pointerInputs.multiTouchPanning = false
          pointerInputs.multiTouchPanAndZoom = false
        }
      }
    }

    this.props.setLoading(true)
    this.assetsLoader.load(nextProps.sceneDef).then(async (sceneResult) => {
      nextProps.updateScene(
        this.renderedProps,
        nextProps,
        sceneResult,
        this.sceneContext,
        nextProps.cameraDef,
      )

      await this.scene.whenReadyAsync()
      this.scene.render()

      this.renderedProps = nextProps

      this.props.setLoading(false)
    })
  }

  assetsLoader
  resizeListener
  canvas
  sceneContext
  scene
  engine
  camera
  cameraBetaDiff = 0.0001
  renderedProps

  visibilityChangeHandler = () => {
    if (document.hidden) {
      this.stopLoop()
    } else {
      this.startLoop()
    }
  }

  startLoop = () => {
    if (!this.engine || this.engine.isDisposed) {
      throw new Error('Engine disposed or not initialized.')
    }

    this.engine.runRenderLoop(() => {
      const isInertiaHappening =
        this.camera.inertialAlphaOffset !== 0 ||
        this.camera.inertialBetaOffset !== 0 ||
        this.camera.inertialRadiusOffset !== 0

      if (isInertiaHappening) {
        this.scene.render()
      }
    })
  }

  stopLoop = () => {
    this.engine.stopRenderLoop()
  }

  render() {
    return (
      <canvas
        style={{
          width: '100%',
          height: '100%',
          touchAction: 'none',
        }}
        ref={(node) => {
          this.canvas = node
        }}
      />
    )
  }
}

export default RenderEngine
