import Bluebird from 'bluebird'
import { mapValues } from '@technically/lodash'

import AbstractLoader, {
  updateSingleLoader,
  updateLoaderMaps,
} from './AbstractLoader'
import { loadData, loadImageToDataUri } from './dataLoaders'

const createLoaders = (def) => ({
  svg: loadData(def.svg),
  images: mapValues(def.images, loadImageToDataUri),
  svgs: mapValues(def.svgs, loadData),
  fonts: mapValues(def.fonts, loadData),
})

const updateLoaders = (prev, next, loaders) => ({
  svg: updateSingleLoader(prev.svg, next.svg, loaders.svg, loadData),
  images: updateLoaderMaps(
    prev.images,
    next.images,
    loaders.images,
    loadImageToDataUri,
  ),
  svgs: updateLoaderMaps(prev.svgs, next.svgs, loaders.svgs, loadData),
  fonts: updateLoaderMaps(prev.fonts, next.fonts, loaders.fonts, loadData),
})

const combineLoaders = (loaders) =>
  new Bluebird((resolve, reject, onCancel) => {
    onCancel(() => {
      // NO-OP - internal promises will be reused
    })

    return Bluebird.props({
      svg: loaders.svg,
      images: Bluebird.props(loaders.images),
      svgs: Bluebird.props(loaders.svgs),
      fonts: Bluebird.props(loaders.fonts),
    }).then(resolve, reject)
  })

export default class DsvgAssetLoader extends AbstractLoader {
  constructor() {
    super({
      createLoaders,
      updateLoaders,
      combineLoaders,
    })
  }
}
