import { PBRMetallicRoughnessMaterial, Color3 } from '@babylonjs/core'

const createBatMaterial = (scene) => {
  const batMaterial = new PBRMetallicRoughnessMaterial('batMaterial', scene)
  batMaterial.baseColor = new Color3(0.9, 0.9, 0.9)
  batMaterial.metallic = 1
  batMaterial.roughness = 1
  batMaterial.environmmentIntensity = 0.75

  return batMaterial
}

const createGripTapeMaterial = (scene) => {
  const batMaterial = new PBRMetallicRoughnessMaterial(
    'gripTapeMaterial',
    scene,
  )
  batMaterial.baseColor = new Color3(0.9, 0.9, 0.9)
  batMaterial.metallic = 1
  batMaterial.roughness = 1
  batMaterial.environmmentIntensity = 0.75

  return batMaterial
}

export { createBatMaterial, createGripTapeMaterial }
