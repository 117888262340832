import Bluebird from 'bluebird'
import { every } from '@technically/lodash'

import singlePixel from './singlePixel.svg'

const WIDTH = 1
const HEIGHT = 1

const randomColorComponent = () => Math.ceil(Math.random() * 255)

const generatePixelPng = (rgba) => {
  const canvas = document.createElement('canvas')
  canvas.width = WIDTH
  canvas.height = HEIGHT
  const ctx = canvas.getContext('2d')
  ctx.fillStyle = `rgba(${rgba[0]},${rgba[1]},${rgba[2]}, 1)`
  ctx.fillRect(0, 0, WIDTH, HEIGHT)
  const imageUrl = canvas.toDataURL('png')
  return imageUrl
}

const getTestData = () => {
  const rgba = [
    randomColorComponent(),
    randomColorComponent(),
    randomColorComponent(),
    255,
  ]
  const imageUrl = generatePixelPng(rgba)
  const svgData = singlePixel.replace(/"data:image\/png.+?"/, `"${imageUrl}"`)
  const dataUrl = `data:image/svg+xml;base64,${window.btoa(
    window.unescape(window.encodeURIComponent(svgData)),
  )}`

  return {
    dataUrl,
    rgba,
  }
}

const getImageData = (dataUrl, ctx) => {
  const image = new Image()
  image.crossOrigin = 'Anonymous'

  return new Promise((resolve, reject) => {
    image.onload = () => {
      ctx.drawImage(image, 0, 0)
      const pixel = ctx.getImageData(0, 0, WIDTH, HEIGHT)
      resolve(pixel)
    }
    image.onerror = (error) => {
      reject(new Error(`Can not load svg image, error: ${error.message}`))
    }
    image.src = dataUrl
  })
}

const imageInsideSvgImageBoolean = (ctx, dataUrl, rgba) =>
  getImageData(dataUrl, ctx)
    .then((pixel) => {
      const newData = pixel.data
      return every(newData, (x, i) => x === rgba[i])
    })
    .catch((error) => {
      console.error(`Test threw exception - ${error.message}`)
      return false
    })

const imageInsideSvgImage = () => {
  const { dataUrl, rgba } = getTestData()

  const canvas = document.createElement('canvas')
  canvas.width = WIDTH
  canvas.height = HEIGHT
  const ctx = canvas.getContext('2d')

  return imageInsideSvgImageBoolean(ctx, dataUrl, rgba)
    .then((result) => {
      if (result === true) {
        return 'supported'
      }
      return Promise.delay(10)
        .then(() => imageInsideSvgImageBoolean(ctx, dataUrl, rgba))
        .then((result2) => {
          if (result2 === true) {
            return 'supportedOnlyWithCache'
          }
          return 'notSupported'
        })
    })
    .catch(() => 'notSupported')
}

let featurePromise
let results

const getResults = () => results

const gatherResults = () => {
  if (featurePromise == null) {
    featurePromise = Bluebird.props({
      imageInsideSvgImage: imageInsideSvgImage(),
      badSvgNamespaces: true,
    }).tap((x) => {
      results = x
    })
  }
  return featurePromise
}

export default { getResults, gatherResults }
