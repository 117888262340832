import { forEach, filter, includes } from '@technically/lodash'
import { Vector3 } from '@babylonjs/core'

let prevSceneResult

const updateModel = (
  scene,
  positionMesh,
  hdr,
  material,
  prevModelDef,
  nextModelDef,
  prevAssets,
  nextAssets,
) => {
  const textures = nextAssets ? nextAssets.textures : {}
  const meshes = nextAssets ? nextAssets.meshes : {}

  const didSceneHdrChange = material.environmentTexture !== hdr
  if (didSceneHdrChange && material) {
    if (hdr) {
      hdr.gammaSpace = false
    }

    material.environmentTexture = hdr
  }

  if (textures) {
    const didBatDiffuseChange = material.baseTexture !== textures.diffuse
    if (didBatDiffuseChange) {
      if (material.baseTexture) {
        material.baseTexture.dispose()
      }

      material.baseTexture = textures.diffuse
    }

    const didBatNormalChange = material.normalTexture !== textures.normal
    if (didBatNormalChange) {
      if (material.normalTexture) {
        material.normalTexture.dispose()
      }

      material.normalTexture = textures.normal
    }

    const didBatRoughnessChange =
      material.metallicRoughnessTexture !== textures.roughness
    if (didBatRoughnessChange) {
      if (material.metallicRoughnessTexture) {
        material.metallicRoughnessTexture.dispose()
      }

      material.metallicRoughnessTexture = textures.roughness
    }
  }

  const prevMesheDefs = prevModelDef ? prevModelDef.meshes : {}
  const nextMesheDefs = nextModelDef ? nextModelDef.meshes : {}
  const didBatMeshChange = prevMesheDefs !== nextMesheDefs
  if (didBatMeshChange) {
    const oldMeshes = filter(
      prevAssets && prevAssets.meshes,
      (mesh) => !includes(meshes, mesh),
    )
    forEach(oldMeshes, (mesh) => {
      scene.removeMesh(mesh)
      mesh.dispose()
    })

    const newMeshes = filter(
      meshes,
      (mesh) => !includes(prevAssets && prevAssets.meshes, mesh),
    )
    forEach(newMeshes, (mesh) => {
      mesh.material = material

      scene.addMesh(mesh)
    })
  }

  const meshDef = nextModelDef
  if (meshDef) {
    forEach(meshes, (mesh, meshId) => {
      mesh.parent = positionMesh

      if (meshDef.meshVisibility) {
        mesh.isVisible =
          meshId in meshDef.meshVisibility ?
            meshDef.meshVisibility[meshId]
          : true
      }
      mesh.scaling = new Vector3(meshDef.scale, meshDef.scale, meshDef.scale)
    })
  }
}

const updateScene = (
  prevProps,
  nextProps,
  sceneResult,
  sceneContext,
  cameraDef,
) => {
  const { scene, materials, rotationMesh, positionMesh } = sceneContext

  const { meshRotation, meshPosition } = cameraDef
  rotationMesh.rotation = new Vector3(
    meshRotation.x,
    meshRotation.y,
    meshRotation.z,
  )
  positionMesh.position = new Vector3(
    meshPosition.x,
    meshPosition.y,
    meshPosition.z,
  )

  updateModel(
    scene,
    positionMesh,
    sceneResult.scene.hdr,
    materials.bat,
    prevProps ? prevProps.sceneDef.models.bat : null,
    nextProps.sceneDef.models.bat,
    prevSceneResult ? prevSceneResult.models.gripTape : null,
    sceneResult.models.bat,
  )
  updateModel(
    scene,
    positionMesh,
    sceneResult.scene.hdr,
    materials.gripTape,
    prevProps ? prevProps.sceneDef.models.gripTape : null,
    nextProps.sceneDef.models.gripTape,
    prevSceneResult ? prevSceneResult.models.gripTape : null,
    sceneResult.models.gripTape,
  )
  prevSceneResult = sceneResult
}

export default updateScene
