// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { GroupRow } from '../typings';

const data = [
  {
    "id": "customGameUseBat",
    "name": "Custom Game Use Bat",
    "description": "Designed for in-game use.  Includes pro player models.",
    "isEnabled": true
  },
  {
    "id": "customFungoBat",
    "name": "Custom Fungo Bat",
    "description": "Crafted to help any coach hit grounders and pop-flys.",
    "isEnabled": false
  },
  {
    "id": "giftAndAwardBat",
    "name": "Gift And Award Bats",
    "description": "Designed to adorn your mantle. Choose from chrome, full size or mini.",
    "isEnabled": false
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<GroupRow[]>> = data;

export type Group = typeof typed[number];

export const GROUP_INDEX_KEY = "id";
export type GroupIndexKey = "id";
export type GroupId = Group["id"];

let i = 0;
export const GROUP_DICT = {
  "customGameUseBat": typed[i++],
  "customFungoBat": typed[i++],
  "giftAndAwardBat": typed[i++]
} as const;

export { typed as GROUP };
