// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SurfaceTypeRow } from '../typings';

const data = [
  {
    "id": "natural",
    "name": "Natural",
    "props": {
      "surfaceStyleId": "natural"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": true
      }
    }
  },
  {
    "id": "flame-light",
    "name": "Light Flame",
    "props": {
      "surfaceStyleId": "flame",
      "surfaceFlameId": "light"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "flame-medium",
    "name": "Medium Flame",
    "props": {
      "surfaceStyleId": "flame",
      "surfaceFlameId": "medium"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "flame-dark",
    "name": "Dark Flame",
    "props": {
      "surfaceStyleId": "flame",
      "surfaceFlameId": "dark"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "flame-burnt",
    "name": "Burnt",
    "props": {
      "surfaceStyleId": "flame",
      "surfaceFlameId": "burnt"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "flame-tar",
    "name": "Flame Tar",
    "props": {
      "surfaceStyleId": "flame",
      "surfaceFlameId": "tar"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": false,
        "personalizedAwardBat": false,
        "customAwardBat": false,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-black",
    "name": "Black",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "black"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": true
      }
    }
  },
  {
    "id": "painted-graphite",
    "name": "Graphite",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "graphite"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-brown",
    "name": "Brown",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "brown"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-hotPink",
    "name": "Hot Pink",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "hotPink"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-wine",
    "name": "Wine",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "wine"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-white",
    "name": "White",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "white"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-royal",
    "name": "Royal",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "royal"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-pink",
    "name": "Pink",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "pink"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-lavender",
    "name": "Lavender",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "lavender"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": false,
        "personalizedAwardBat": false,
        "customAwardBat": false,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-navy",
    "name": "Navy",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "navy"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-lightOrange",
    "name": "Light Orange",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "lightOrange"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-columbiaBlue",
    "name": "Columbia Blue",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "columbiaBlue"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-orange",
    "name": "Orange",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "orange"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-scarlet",
    "name": "Scarlet",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "scarlet"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-purple",
    "name": "Purple",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "purple"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-athleticGold",
    "name": "Athletic Gold",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "athleticGold"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-metallicGold",
    "name": "Metallic Gold",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "metallicGold"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-teal",
    "name": "Teal",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "teal"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-darkGreen",
    "name": "Dark Green",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "darkGreen"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-limeGreen",
    "name": "Lime Green",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "limeGreen"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-coolGray",
    "name": "Cool Gray",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "coolGray"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-hickory",
    "name": "Hickory",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "hickory"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-honey",
    "name": "Honey",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "honey"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "stained-hickory",
    "name": "Hickory Stained",
    "props": {
      "surfaceStyleId": "stained",
      "surfaceColorId": "hickory"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "stained-honey",
    "name": "Honey Stained",
    "props": {
      "surfaceStyleId": "stained",
      "surfaceColorId": "honey"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "stained-gray",
    "name": "Gray Stained",
    "props": {
      "surfaceStyleId": "stained",
      "surfaceColorId": "gray"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "stained-wine",
    "name": "Wine Stained",
    "props": {
      "surfaceStyleId": "stained",
      "surfaceColorId": "wine"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "stained-black",
    "name": "Black Stained",
    "props": {
      "surfaceStyleId": "stained",
      "surfaceColorId": "black"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "stained-brown",
    "name": "Brown Stained",
    "props": {
      "surfaceStyleId": "stained",
      "surfaceColorId": "brown"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "stained-scarlet",
    "name": "Scarlet Stained",
    "props": {
      "surfaceStyleId": "stained",
      "surfaceColorId": "scarlet"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-gray",
    "name": "Gray",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "gray"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": false,
        "personalizedAwardBat": false,
        "customAwardBat": false,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-sky",
    "name": "Sky",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "sky"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": false,
        "personalizedAwardBat": false,
        "customAwardBat": false,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-cloud",
    "name": "Cloud",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "cloud"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": false,
        "personalizedAwardBat": false,
        "customAwardBat": false,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-seafoam",
    "name": "Seafoam",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "seafoam"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": false,
        "personalizedAwardBat": false,
        "customAwardBat": false,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-mint",
    "name": "Mint",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "mint"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": false,
        "personalizedAwardBat": false,
        "customAwardBat": false,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-militaryGreen",
    "name": "Military Green",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "militaryGreen"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": false,
        "personalizedAwardBat": false,
        "customAwardBat": false,
        "customMiniBat": false
      }
    }
  },
  {
    "id": "painted-softwareGray",
    "name": "Software Gray",
    "props": {
      "surfaceStyleId": "painted",
      "surfaceColorId": "softwareGray"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": false,
        "personalizedAwardBat": false,
        "customAwardBat": false,
        "customMiniBat": false
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SurfaceTypeRow[]>> = data;

export type SurfaceType = typeof typed[number];

export const SURFACE_TYPE_INDEX_KEY = "id";
export type SurfaceTypeIndexKey = "id";
export type SurfaceTypeId = SurfaceType["id"];

let i = 0;
export const SURFACE_TYPE_DICT = {
  "natural": typed[i++],
  "flame-light": typed[i++],
  "flame-medium": typed[i++],
  "flame-dark": typed[i++],
  "flame-burnt": typed[i++],
  "flame-tar": typed[i++],
  "painted-black": typed[i++],
  "painted-graphite": typed[i++],
  "painted-brown": typed[i++],
  "painted-hotPink": typed[i++],
  "painted-wine": typed[i++],
  "painted-white": typed[i++],
  "painted-royal": typed[i++],
  "painted-pink": typed[i++],
  "painted-lavender": typed[i++],
  "painted-navy": typed[i++],
  "painted-lightOrange": typed[i++],
  "painted-columbiaBlue": typed[i++],
  "painted-orange": typed[i++],
  "painted-scarlet": typed[i++],
  "painted-purple": typed[i++],
  "painted-athleticGold": typed[i++],
  "painted-metallicGold": typed[i++],
  "painted-teal": typed[i++],
  "painted-darkGreen": typed[i++],
  "painted-limeGreen": typed[i++],
  "painted-coolGray": typed[i++],
  "painted-hickory": typed[i++],
  "painted-honey": typed[i++],
  "stained-hickory": typed[i++],
  "stained-honey": typed[i++],
  "stained-gray": typed[i++],
  "stained-wine": typed[i++],
  "stained-black": typed[i++],
  "stained-brown": typed[i++],
  "stained-scarlet": typed[i++],
  "painted-gray": typed[i++],
  "painted-sky": typed[i++],
  "painted-cloud": typed[i++],
  "painted-seafoam": typed[i++],
  "painted-mint": typed[i++],
  "painted-militaryGreen": typed[i++],
  "painted-softwareGray": typed[i++]
} as const;

export { typed as SURFACE_TYPE };
