// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { GripTapeRow } from '../typings';

const data = [
  {
    "id": "flag",
    "name": "Flag"
  },
  {
    "id": "splaterOpticYellow",
    "name": "Splatter Optic Yellow"
  },
  {
    "id": "splaterBlue",
    "name": "Splatter Blue"
  },
  {
    "id": "splaterFoil",
    "name": "Splatter Foil"
  },
  {
    "id": "orange",
    "name": "Orange"
  },
  {
    "id": "green",
    "name": "Green"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<GripTapeRow[]>> = data;

export type GripTape = typeof typed[number];

export const GRIP_TAPE_INDEX_KEY = "id";
export type GripTapeIndexKey = "id";
export type GripTapeId = GripTape["id"];

let i = 0;
export const GRIP_TAPE_DICT = {
  "flag": typed[i++],
  "splaterOpticYellow": typed[i++],
  "splaterBlue": typed[i++],
  "splaterFoil": typed[i++],
  "orange": typed[i++],
  "green": typed[i++]
} as const;

export { typed as GRIP_TAPE };
