import Bluebird from 'bluebird'
import {
  compact,
  concat,
  difference,
  isEqual,
  values,
} from '@technically/lodash'

import DsvgAssetLoader from './DsvgAssetLoader'
import { getImageCanvasTexture } from './dataLoaders'
import featureTests from './featureTests'

export default class DsvgBuilder {
  loader

  bluebird = null
  def = null
  result = null

  baseSvg
  warmImageUrls

  constructor() {
    this.loader = new DsvgAssetLoader()
    this.warmImageUrls = []

    featureTests.gatherResults()
  }

  load = (def) => {
    if (this.bluebird && this.bluebird.isPending()) {
      this.bluebird.cancel()
    }
    this.bluebird = this._getNewBluebird(def)
    return this.bluebird
  }

  async _buildTexture(def, assets) {
    if (!this.def || this.def.assets.svg !== def.assets.svg) {
      this.baseSvg = def.instructions.getBaseSvg(assets.svg)
    }

    const drawing = def.instructions.getDynamicSvg(
      def.data,
      def.size,
      this.baseSvg,
      assets.images,
      assets.svgs,
      assets.fonts,
    )

    const serializer = new XMLSerializer()
    let svgString = serializer.serializeToString(drawing.node)

    let features = featureTests.getResults()
    if (features == null) {
      // throw new Error('features not detected')
      features = await featureTests.gatherResults()
    }

    if (features.badSvgNamespaces) {
      // https://stackoverflow.com/a/30273776/1398700
      svgString = svgString.replace(' xlink=', ' xmlns:xlink=')
      svgString = svgString.replace(
        /xmlns:ns.+?="http:\/\/www.w3.org\/1999\/xlink"/gi,
        'xmlns:xlink="http://www.w3.org/1999/xlink"',
      )
      svgString = svgString.replace(/ ns\d+:.+?=".*?"/gi, '')
      svgString = svgString.replace(/ xmlns:ns.+?=".*?"/gi, '')
      svgString = svgString.replace(/ svgjs:data=".*?"/gi, '') // text
    }

    const dataUrl = `data:image/svg+xml;base64,${window.btoa(
      window.unescape(window.encodeURIComponent(svgString)),
    )}`
    const textureId = JSON.stringify({
      data: def.data,
      assets: def.assets,
      size: def.size,
    })

    if (features.imageInsideSvgImage === 'supportedOnlyWithCache') {
      await this.warmupImages(def, dataUrl, textureId)
    }

    return getImageCanvasTexture(dataUrl, textureId)
  }

  _getInternalBluebird(def) {
    return this.loader
      .load(def.assets)
      .then((assets) => this._buildTexture(def, assets))
  }

  _getNewBluebird(def) {
    if (this.def && isEqual(def, this.def)) {
      this.def = def
      return Bluebird.resolve(this.result)
    }

    return this._getInternalBluebird(def).tap((result) => {
      this.result = result
      this.def = def
    })
  }

  warmupImages = async (def, dataUrl, name) => {
    const missingImageUrls = difference(
      compact(values(def.assets.images)),
      this.warmImageUrls,
    )

    if (missingImageUrls.length === 0) {
      return
    }

    const warmup = await getImageCanvasTexture(dataUrl, `${name}-warmup`)
    warmup.dispose()

    await new Promise((resolveTimeout) => {
      setTimeout(resolveTimeout, 1000)
    })
    this.warmImageUrls = concat(this.warmImageUrls, missingImageUrls)
  }

  dispose = () => {
    this.loader.dispose()
    if (this.bluebird && this.bluebird.isPending()) {
      this.bluebird.cancel()
    }
    this.bluebird = null
    this.def = null
    this.result = null
    this.baseSvg = ''
  }
}
