import { find, get } from '@technically/lodash'
import { connect } from 'react-redux'
import { setPreviewGenerator } from '~p/client/common/actions'

import { setFocusMode } from '~rawlings/client/actions'

import controlTree from '../../client/controlTree'
import { VIEW_DEF_DICT } from '../../common/sheets'

import Render3d from '../components/Render3d'
import get3dConfig from '../get3dConfig'

const VIEWER_OFFSET = {
  DESKTOP_DEFAULT: { x: -400 },
}

const mapStateToProps = (state) => {
  const { isFocusMode } = state
  const expandedRecipe = controlTree.getExpandedRecipeNested(state)

  const sceneDef = get3dConfig(expandedRecipe)

  const viewDefGroupId = get(
    expandedRecipe,
    'product.model.props.viewDefGroupId',
  )

  const activeView = isFocusMode ? 'overview' : state.render3d.activeView
  const viewDef =
    find(VIEW_DEF_DICT, { groupId: viewDefGroupId, viewName: activeView }) ||
    find(VIEW_DEF_DICT, { viewName: 'overview' })

  let cameraDef = viewDef.cameraDef
  if (state.layoutMode === 'desktop') {
    cameraDef = {
      ...cameraDef,
      radius: cameraDef.desktopRadius,
      translateProjection: isFocusMode ? {} : VIEWER_OFFSET.DESKTOP_DEFAULT,
    }
  }

  return { expandedRecipe, sceneDef, cameraDef, isFocusMode }
}

const mapDispatchToProps = {
  setFocusMode,
  setPreviewGenerator,
}

export default connect(mapStateToProps, mapDispatchToProps)(Render3d)
