import * as React from 'react'

import TextAndFontInput from '~rawlings/client/components/TextAndFontInput'

import './EndSectionTexts.css'

const EndSectionTexts = () => (
  <div className="EndSectionTexts">
    <TextAndFontInput
      textNodeKeyPath="decoration.endSection.upperText"
      fontNodeKeyPath="decoration.endSection.upperFont"
      hasMargin={false}
    />

    <TextAndFontInput
      textNodeKeyPath="decoration.endSection.middleText"
      fontNodeKeyPath="decoration.endSection.middleFont"
      hasMargin={false}
    />

    <TextAndFontInput
      textNodeKeyPath="decoration.endSection.lowerText"
      fontNodeKeyPath="decoration.endSection.lowerFont"
      hasMargin={false}
    />
  </div>
)

export default EndSectionTexts
