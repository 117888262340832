// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ColorComboRow } from '../typings';

const data = [
  {
    "id": "blackWithSilver",
    "name": "Black with Silver",
    "limitations": {
      "surfaceTypeId": "painted-black",
      "stripeColorId": "silver",
      "stampColorId": "silver"
    },
    "props": {
      "hex": "#231f20",
      "tileImageSrc": "colorCombo/secondarySilver.svg"
    }
  },
  {
    "id": "naturalWithBlack",
    "name": "Natural with Black",
    "limitations": {
      "surfaceTypeId": "natural",
      "stripeColorId": "black",
      "stampColorId": "black"
    },
    "props": {
      "imageId": "regular",
      "tileImageSrc": "colorCombo/secondaryBlack.svg"
    }
  },
  {
    "id": "honeyWithBlack",
    "name": "Honey with Black and Silver",
    "limitations": {
      "surfaceTypeId": "stained-honey",
      "stripeColorId": "silver",
      "stampColorId": "silver",
      "handleSurfaceTypeId": "painted-black"
    },
    "props": {
      "hex": "#f9b53f",
      "tileImageSrc": "colorCombo/secondaryBlack.svg"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ColorComboRow[]>> = data;

export type ColorCombo = typeof typed[number];

export const COLOR_COMBO_INDEX_KEY = "id";
export type ColorComboIndexKey = "id";
export type ColorComboId = ColorCombo["id"];

let i = 0;
export const COLOR_COMBO_DICT = {
  "blackWithSilver": typed[i++],
  "naturalWithBlack": typed[i++],
  "honeyWithBlack": typed[i++]
} as const;

export { typed as COLOR_COMBO };
