// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SummaryTreeItemRow } from '../typings';

const data = [
  {
    "childId": "product",
    "name": "Product",
    "id": "product"
  },
  {
    "parentId": "product",
    "childId": "category",
    "name": "Category",
    "propId": "filter.category",
    "id": "product.category"
  },
  {
    "parentId": "product",
    "childId": "series",
    "name": "Series",
    "propId": "filter.series",
    "id": "product.series"
  },
  {
    "parentId": "product",
    "childId": "model",
    "name": "Model",
    "propId": "product.model",
    "id": "product.model"
  },
  {
    "parentId": "product",
    "childId": "woodSpecies",
    "name": "Wood Species",
    "propId": "product.woodSpecies",
    "id": "product.woodSpecies"
  },
  {
    "parentId": "product",
    "childId": "length",
    "name": "Length",
    "propId": "product.length",
    "id": "product.length"
  },
  {
    "parentId": "product",
    "childId": "weight",
    "name": "Weight",
    "propId": "product.weight",
    "id": "product.weight"
  },
  {
    "childId": "colors",
    "name": "Colors",
    "id": "colors"
  },
  {
    "parentId": "colors",
    "childId": "colorCombo",
    "name": "Color Combo",
    "propId": "colors.colorCombo",
    "id": "colors.colorCombo"
  },
  {
    "parentId": "colors",
    "childId": "batColor",
    "name": "Bat",
    "propId": "colors.batColor",
    "id": "colors.batColor"
  },
  {
    "parentId": "colors",
    "childId": "barrel",
    "name": "Barrel",
    "id": "colors.barrel"
  },
  {
    "parentId": "colors.barrel",
    "childId": "type",
    "name": "Color",
    "propId": "colors.barrel.type",
    "id": "colors.barrel.type"
  },
  {
    "parentId": "colors.barrel",
    "childId": "finish",
    "name": "Finish",
    "propId": "colors.barrel.finish",
    "id": "colors.barrel.finish"
  },
  {
    "parentId": "colors",
    "childId": "handle",
    "name": "Handle",
    "id": "colors.handle"
  },
  {
    "parentId": "colors.handle",
    "childId": "type",
    "name": "Color",
    "propId": "colors.handle.type",
    "id": "colors.handle.type"
  },
  {
    "parentId": "colors.handle",
    "childId": "finish",
    "name": "Finish",
    "propId": "colors.handle.finish",
    "id": "colors.handle.finish"
  },
  {
    "parentId": "colors",
    "childId": "stripe",
    "name": "Stripe",
    "id": "colors.stripe"
  },
  {
    "parentId": "colors.stripe",
    "childId": "size",
    "name": "Size",
    "propId": "colors.stripe.size",
    "id": "colors.stripe.size"
  },
  {
    "parentId": "colors.stripe",
    "childId": "color",
    "name": "Color",
    "propId": "colors.stripe.color",
    "id": "colors.stripe.color"
  },
  {
    "parentId": "colors",
    "childId": "color",
    "name": "Rawlings Logo",
    "propId": "colors.ovalLogo",
    "id": "colors.color"
  },
  {
    "childId": "decoration",
    "name": "Decoration",
    "id": "decoration"
  },
  {
    "parentId": "decoration",
    "childId": "middleSection",
    "name": "Middle Section",
    "id": "decoration.middleSection"
  },
  {
    "parentId": "decoration.middleSection",
    "childId": "type",
    "name": "Type",
    "propId": "decoration.middleSection.type",
    "id": "decoration.middleSection.type"
  },
  {
    "parentId": "decoration.middleSection",
    "childId": "customLogo",
    "name": "Custom Logo",
    "propId": "decoration.middleSection.customLogo",
    "id": "decoration.middleSection.customLogo"
  },
  {
    "parentId": "decoration.middleSection",
    "childId": "stockLogo",
    "name": "Stock Logo",
    "propId": "decoration.middleSection.stockLogo",
    "id": "decoration.middleSection.stockLogo"
  },
  {
    "parentId": "decoration.middleSection",
    "childId": "color",
    "name": "Color",
    "propId": "decoration.middleSection.color",
    "id": "decoration.middleSection.color"
  },
  {
    "parentId": "decoration",
    "childId": "endSection",
    "name": "End Section",
    "id": "decoration.endSection"
  },
  {
    "parentId": "decoration.endSection",
    "childId": "type",
    "name": "Type",
    "propId": "decoration.endSection.type",
    "id": "decoration.endSection.type"
  },
  {
    "parentId": "decoration.endSection",
    "childId": "customLogo",
    "name": "Custom Logo",
    "propId": "decoration.endSection.customLogo",
    "id": "decoration.endSection.customLogo"
  },
  {
    "parentId": "decoration.endSection",
    "childId": "stockLogo",
    "name": "Stock Logo",
    "propId": "decoration.endSection.stockLogo",
    "id": "decoration.endSection.stockLogo"
  },
  {
    "parentId": "decoration.endSection",
    "childId": "upperText",
    "name": "Upper Text",
    "propId": "decoration.endSection.upperText",
    "id": "decoration.endSection.upperText"
  },
  {
    "parentId": "decoration.endSection",
    "childId": "middleText",
    "name": "Middle Text",
    "propId": "decoration.endSection.middleText",
    "id": "decoration.endSection.middleText"
  },
  {
    "parentId": "decoration.endSection",
    "childId": "lowerText",
    "name": "Lower Text",
    "propId": "decoration.endSection.lowerText",
    "id": "decoration.endSection.lowerText"
  },
  {
    "parentId": "decoration.endSection",
    "childId": "color",
    "name": "Color",
    "propId": "decoration.endSection.color",
    "id": "decoration.endSection.color"
  },
  {
    "childId": "options",
    "name": "Options",
    "id": "options"
  },
  {
    "parentId": "options",
    "childId": "gripTape",
    "name": "Grip Tape",
    "propId": "options.gripTape",
    "id": "options.gripTape"
  },
  {
    "parentId": "options",
    "childId": "cuppedEnd",
    "name": "Cupped End",
    "propId": "options.cuppedEnd",
    "id": "options.cuppedEnd"
  },
  {
    "parentId": "options",
    "childId": "knobStyle",
    "name": "Knob Style",
    "propId": "options.knobStyle",
    "id": "options.knobStyle"
  },
  {
    "parentId": "options",
    "childId": "displayTube",
    "name": "Display Tube",
    "propId": "options.displayTube",
    "id": "options.displayTube"
  },
  {
    "parentId": "decoration",
    "childId": "knobText",
    "name": "Knob Text",
    "id": "decoration.knobText"
  },
  {
    "parentId": "decoration.knobText",
    "childId": "text",
    "name": "Text",
    "propId": "decoration.knobText.text",
    "id": "decoration.knobText.text"
  },
  {
    "parentId": "decoration.knobText",
    "childId": "color",
    "name": "Color",
    "propId": "decoration.knobText.color",
    "id": "decoration.knobText.color"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SummaryTreeItemRow[]>> = data;

export type SummaryTreeItem = typeof typed[number];

export const SUMMARY_TREE_ITEM_INDEX_KEY = "id";
export type SummaryTreeItemIndexKey = "id";
export type SummaryTreeItemId = SummaryTreeItem["id"];

let i = 0;
export const SUMMARY_TREE_ITEM_DICT = {
  "product": typed[i++],
  "product.category": typed[i++],
  "product.series": typed[i++],
  "product.model": typed[i++],
  "product.woodSpecies": typed[i++],
  "product.length": typed[i++],
  "product.weight": typed[i++],
  "colors": typed[i++],
  "colors.colorCombo": typed[i++],
  "colors.batColor": typed[i++],
  "colors.barrel": typed[i++],
  "colors.barrel.type": typed[i++],
  "colors.barrel.finish": typed[i++],
  "colors.handle": typed[i++],
  "colors.handle.type": typed[i++],
  "colors.handle.finish": typed[i++],
  "colors.stripe": typed[i++],
  "colors.stripe.size": typed[i++],
  "colors.stripe.color": typed[i++],
  "colors.color": typed[i++],
  "decoration": typed[i++],
  "decoration.middleSection": typed[i++],
  "decoration.middleSection.type": typed[i++],
  "decoration.middleSection.customLogo": typed[i++],
  "decoration.middleSection.stockLogo": typed[i++],
  "decoration.middleSection.color": typed[i++],
  "decoration.endSection": typed[i++],
  "decoration.endSection.type": typed[i++],
  "decoration.endSection.customLogo": typed[i++],
  "decoration.endSection.stockLogo": typed[i++],
  "decoration.endSection.upperText": typed[i++],
  "decoration.endSection.middleText": typed[i++],
  "decoration.endSection.lowerText": typed[i++],
  "decoration.endSection.color": typed[i++],
  "options": typed[i++],
  "options.gripTape": typed[i++],
  "options.cuppedEnd": typed[i++],
  "options.knobStyle": typed[i++],
  "options.displayTube": typed[i++],
  "decoration.knobText": typed[i++],
  "decoration.knobText.text": typed[i++],
  "decoration.knobText.color": typed[i++]
} as const;

export { typed as SUMMARY_TREE };
