// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { StockLogoRow } from '../typings';

const data = [
  {
    "id": "abc",
    "name": "ABC"
  },
  {
    "id": "graduation",
    "name": "Graduation"
  },
  {
    "id": "match",
    "name": "Match"
  },
  {
    "id": "happyBirthday",
    "name": "Happy Birthday"
  },
  {
    "id": "happyFatherSDay",
    "name": "Happy Father's Day"
  },
  {
    "id": "bells",
    "name": "Bells"
  },
  {
    "id": "happyMotherSDay",
    "name": "Happy Mother's Day"
  },
  {
    "id": "engagement",
    "name": "Engagement"
  },
  {
    "id": "itSABoy",
    "name": "It's a Boy!"
  },
  {
    "id": "itSAGirl",
    "name": "It's a Girl!"
  },
  {
    "id": "stork",
    "name": "Stork"
  },
  {
    "id": "USFLAG",
    "name": "USFLAG"
  },
  {
    "id": "7ON7",
    "name": "7ON7"
  },
  {
    "id": "BASEBALL1",
    "name": "BASEBALL1"
  },
  {
    "id": "BASEBALL2",
    "name": "BASEBALL2"
  },
  {
    "id": "BASEBALL3",
    "name": "BASEBALL3"
  },
  {
    "id": "BASEBALL4",
    "name": "BASEBALL4"
  },
  {
    "id": "BASEBALL5",
    "name": "BASEBALL5"
  },
  {
    "id": "BASEBALL6",
    "name": "BASEBALL6"
  },
  {
    "id": "BASKETBALL1",
    "name": "BASKETBALL1"
  },
  {
    "id": "BASKETBALL2",
    "name": "BASKETBALL2"
  },
  {
    "id": "BASKETBALL3",
    "name": "BASKETBALL3"
  },
  {
    "id": "BASKETBALL4",
    "name": "BASKETBALL4"
  },
  {
    "id": "BEAR1",
    "name": "BEAR1"
  },
  {
    "id": "BEAR2",
    "name": "BEAR2"
  },
  {
    "id": "BEAR3",
    "name": "BEAR3"
  },
  {
    "id": "BEAR4",
    "name": "BEAR4"
  },
  {
    "id": "BEAR5",
    "name": "BEAR5"
  },
  {
    "id": "BEAR6",
    "name": "BEAR6"
  },
  {
    "id": "BIRD1",
    "name": "BIRD1"
  },
  {
    "id": "BIRD2",
    "name": "BIRD2"
  },
  {
    "id": "BIRD3",
    "name": "BIRD3"
  },
  {
    "id": "BIRD4",
    "name": "BIRD4"
  },
  {
    "id": "BIRD5",
    "name": "BIRD5"
  },
  {
    "id": "BIRD6",
    "name": "BIRD6"
  },
  {
    "id": "BIRD7",
    "name": "BIRD7"
  },
  {
    "id": "BIRD8",
    "name": "BIRD8"
  },
  {
    "id": "BIRD9",
    "name": "BIRD9"
  },
  {
    "id": "BIRD10",
    "name": "BIRD10"
  },
  {
    "id": "BIRD11",
    "name": "BIRD11"
  },
  {
    "id": "BIRD12",
    "name": "BIRD12"
  },
  {
    "id": "BIRD13",
    "name": "BIRD13"
  },
  {
    "id": "BIRD14",
    "name": "BIRD14"
  },
  {
    "id": "BIRD15",
    "name": "BIRD15"
  },
  {
    "id": "BIRD16",
    "name": "BIRD16"
  },
  {
    "id": "BIRD17",
    "name": "BIRD17"
  },
  {
    "id": "BIRD18",
    "name": "BIRD18"
  },
  {
    "id": "BIRD19",
    "name": "BIRD19"
  },
  {
    "id": "BIRD20",
    "name": "BIRD20"
  },
  {
    "id": "BIRD21",
    "name": "BIRD21"
  },
  {
    "id": "BIRD22",
    "name": "BIRD22"
  },
  {
    "id": "BIRD23",
    "name": "BIRD23"
  },
  {
    "id": "BIRD24",
    "name": "BIRD24"
  },
  {
    "id": "BIRD25",
    "name": "BIRD25"
  },
  {
    "id": "BIRD26",
    "name": "BIRD26"
  },
  {
    "id": "BIRD27",
    "name": "BIRD27"
  },
  {
    "id": "BIRD28",
    "name": "BIRD28"
  },
  {
    "id": "BIRD29",
    "name": "BIRD29"
  },
  {
    "id": "BIRD30",
    "name": "BIRD30"
  },
  {
    "id": "BRAVE1",
    "name": "BRAVE1"
  },
  {
    "id": "BRAVE2",
    "name": "BRAVE2"
  },
  {
    "id": "BUCCANEER",
    "name": "BUCCANEER"
  },
  {
    "id": "BUFFALO",
    "name": "BUFFALO"
  },
  {
    "id": "BULLDOG1",
    "name": "BULLDOG1"
  },
  {
    "id": "BULLDOG2",
    "name": "BULLDOG2"
  },
  {
    "id": "BULLDOG3",
    "name": "BULLDOG3"
  },
  {
    "id": "BULLDOG4",
    "name": "BULLDOG4"
  },
  {
    "id": "BULLDOG5",
    "name": "BULLDOG5"
  },
  {
    "id": "BULLDOG6",
    "name": "BULLDOG6"
  },
  {
    "id": "BULLDOG7",
    "name": "BULLDOG7"
  },
  {
    "id": "BULLDOG8",
    "name": "BULLDOG8"
  },
  {
    "id": "BULLDOG9",
    "name": "BULLDOG9"
  },
  {
    "id": "BULLDOG10",
    "name": "BULLDOG10"
  },
  {
    "id": "CARDINAL1",
    "name": "CARDINAL1"
  },
  {
    "id": "CARDINAL2",
    "name": "CARDINAL2"
  },
  {
    "id": "CAT1",
    "name": "CAT1"
  },
  {
    "id": "CAT2",
    "name": "CAT2"
  },
  {
    "id": "CAT3",
    "name": "CAT3"
  },
  {
    "id": "CAT4",
    "name": "CAT4"
  },
  {
    "id": "CAT5",
    "name": "CAT5"
  },
  {
    "id": "CAT6",
    "name": "CAT6"
  },
  {
    "id": "CAT7",
    "name": "CAT7"
  },
  {
    "id": "CAT8",
    "name": "CAT8"
  },
  {
    "id": "CAT9",
    "name": "CAT9"
  },
  {
    "id": "CAT10",
    "name": "CAT10"
  },
  {
    "id": "CAT11",
    "name": "CAT11"
  },
  {
    "id": "CAT12",
    "name": "CAT12"
  },
  {
    "id": "CAT13",
    "name": "CAT13"
  },
  {
    "id": "CAT14",
    "name": "CAT14"
  },
  {
    "id": "CAT15",
    "name": "CAT15"
  },
  {
    "id": "CAT16",
    "name": "CAT16"
  },
  {
    "id": "CAT17",
    "name": "CAT17"
  },
  {
    "id": "CAT18",
    "name": "CAT18"
  },
  {
    "id": "CAT19",
    "name": "CAT19"
  },
  {
    "id": "CAVALIER1",
    "name": "CAVALIER1"
  },
  {
    "id": "CAVALIER2",
    "name": "CAVALIER2"
  },
  {
    "id": "CAVALIER3",
    "name": "CAVALIER3"
  },
  {
    "id": "CHIEF",
    "name": "CHIEF"
  },
  {
    "id": "COBRA",
    "name": "COBRA"
  },
  {
    "id": "COMICTROJAN",
    "name": "COMICTROJAN"
  },
  {
    "id": "COUGAR",
    "name": "COUGAR"
  },
  {
    "id": "DEVIL",
    "name": "DEVIL"
  },
  {
    "id": "DEVIL2",
    "name": "DEVIL2"
  },
  {
    "id": "DEVIL3",
    "name": "DEVIL3"
  },
  {
    "id": "DEVIL4",
    "name": "DEVIL4"
  },
  {
    "id": "DEVIL5",
    "name": "DEVIL5"
  },
  {
    "id": "DRAGON",
    "name": "DRAGON"
  },
  {
    "id": "DRAGONH13",
    "name": "DRAGONH13"
  },
  {
    "id": "DRAGONI13",
    "name": "DRAGONI13"
  },
  {
    "id": "EAGLE1",
    "name": "EAGLE1"
  },
  {
    "id": "EAGLE2",
    "name": "EAGLE2"
  },
  {
    "id": "EAGLE3",
    "name": "EAGLE3"
  },
  {
    "id": "EAGLE4",
    "name": "EAGLE4"
  },
  {
    "id": "EAGLE5",
    "name": "EAGLE5"
  },
  {
    "id": "FOOTBALL1",
    "name": "FOOTBALL1"
  },
  {
    "id": "FOOTBALL2",
    "name": "FOOTBALL2"
  },
  {
    "id": "FOOTBALL3",
    "name": "FOOTBALL3"
  },
  {
    "id": "FOOTBALL4",
    "name": "FOOTBALL4"
  },
  {
    "id": "FOOTBALL5",
    "name": "FOOTBALL5"
  },
  {
    "id": "HELMET",
    "name": "HELMET"
  },
  {
    "id": "HORSE1",
    "name": "HORSE1"
  },
  {
    "id": "HORSE10",
    "name": "HORSE10"
  },
  {
    "id": "HORSE2",
    "name": "HORSE2"
  },
  {
    "id": "HORSE3",
    "name": "HORSE3"
  },
  {
    "id": "HORSE4",
    "name": "HORSE4"
  },
  {
    "id": "HORSE5",
    "name": "HORSE5"
  },
  {
    "id": "HORSE6",
    "name": "HORSE6"
  },
  {
    "id": "HORSE7",
    "name": "HORSE7"
  },
  {
    "id": "HORSE8",
    "name": "HORSE8"
  },
  {
    "id": "HORSE9",
    "name": "HORSE9"
  },
  {
    "id": "HUSKYF1",
    "name": "HUSKYF1"
  },
  {
    "id": "INDIAN1",
    "name": "INDIAN1"
  },
  {
    "id": "INDIAN2",
    "name": "INDIAN2"
  },
  {
    "id": "INDIAN3",
    "name": "INDIAN3"
  },
  {
    "id": "INDIAN4",
    "name": "INDIAN4"
  },
  {
    "id": "INDIAN5",
    "name": "INDIAN5"
  },
  {
    "id": "INDIAN6",
    "name": "INDIAN6"
  },
  {
    "id": "INDIAN7",
    "name": "INDIAN7"
  },
  {
    "id": "INDIAN8",
    "name": "INDIAN8"
  },
  {
    "id": "INDIAN9",
    "name": "INDIAN9"
  },
  {
    "id": "INDIAN10",
    "name": "INDIAN10"
  },
  {
    "id": "INDIAN11",
    "name": "INDIAN11"
  },
  {
    "id": "INDIAN12",
    "name": "INDIAN12"
  },
  {
    "id": "INDIAN13",
    "name": "INDIAN13"
  },
  {
    "id": "INDIAN14",
    "name": "INDIAN14"
  },
  {
    "id": "INDIAN15",
    "name": "INDIAN15"
  },
  {
    "id": "INSECT1",
    "name": "INSECT1"
  },
  {
    "id": "INSECT2",
    "name": "INSECT2"
  },
  {
    "id": "INSECT3",
    "name": "INSECT3"
  },
  {
    "id": "INSECT4",
    "name": "INSECT4"
  },
  {
    "id": "INSECT5",
    "name": "INSECT5"
  },
  {
    "id": "KNIGHT1",
    "name": "KNIGHT1"
  },
  {
    "id": "KNIGHT2",
    "name": "KNIGHT2"
  },
  {
    "id": "KNIGHT3",
    "name": "KNIGHT3"
  },
  {
    "id": "KNIGHT4",
    "name": "KNIGHT4"
  },
  {
    "id": "KNIGHT5",
    "name": "KNIGHT5"
  },
  {
    "id": "KNIGHT6",
    "name": "KNIGHT6"
  },
  {
    "id": "KNIGHT7",
    "name": "KNIGHT7"
  },
  {
    "id": "KNIGHT8",
    "name": "KNIGHT8"
  },
  {
    "id": "KNIGHT9",
    "name": "KNIGHT9"
  },
  {
    "id": "KNIGHT10",
    "name": "KNIGHT10"
  },
  {
    "id": "LIGHTNING1",
    "name": "LIGHTNING1"
  },
  {
    "id": "LIGHTNING2",
    "name": "LIGHTNING2"
  },
  {
    "id": "LIGHTNING3",
    "name": "LIGHTNING3"
  },
  {
    "id": "LIGHTNING4",
    "name": "LIGHTNING4"
  },
  {
    "id": "LION1",
    "name": "LION1"
  },
  {
    "id": "LION2",
    "name": "LION2"
  },
  {
    "id": "LION3",
    "name": "LION3"
  },
  {
    "id": "LION4",
    "name": "LION4"
  },
  {
    "id": "LION5",
    "name": "LION5"
  },
  {
    "id": "LION6",
    "name": "LION6"
  },
  {
    "id": "LION7",
    "name": "LION7"
  },
  {
    "id": "LION8",
    "name": "LION8"
  },
  {
    "id": "LONGHORNS",
    "name": "LONGHORNS"
  },
  {
    "id": "MARLIN",
    "name": "MARLIN"
  },
  {
    "id": "MISC1",
    "name": "MISC1"
  },
  {
    "id": "MISC2",
    "name": "MISC2"
  },
  {
    "id": "MISC3",
    "name": "MISC3"
  },
  {
    "id": "MISC4",
    "name": "MISC4"
  },
  {
    "id": "MISC5",
    "name": "MISC5"
  },
  {
    "id": "MISC6",
    "name": "MISC6"
  },
  {
    "id": "MISC7",
    "name": "MISC7"
  },
  {
    "id": "MISC8",
    "name": "MISC8"
  },
  {
    "id": "MISC9",
    "name": "MISC9"
  },
  {
    "id": "MISC10",
    "name": "MISC10"
  },
  {
    "id": "MISC11",
    "name": "MISC11"
  },
  {
    "id": "MISC12",
    "name": "MISC12"
  },
  {
    "id": "MISC13",
    "name": "MISC13"
  },
  {
    "id": "MISC14",
    "name": "MISC14"
  },
  {
    "id": "MISC15",
    "name": "MISC15"
  },
  {
    "id": "MISC16",
    "name": "MISC16"
  },
  {
    "id": "MISC17",
    "name": "MISC17"
  },
  {
    "id": "MISC18",
    "name": "MISC18"
  },
  {
    "id": "MISC19",
    "name": "MISC19"
  },
  {
    "id": "MISC20",
    "name": "MISC20"
  },
  {
    "id": "MISC21",
    "name": "MISC21"
  },
  {
    "id": "MISC22",
    "name": "MISC22"
  },
  {
    "id": "MISC23",
    "name": "MISC23"
  },
  {
    "id": "MUSTANG1",
    "name": "MUSTANG1"
  },
  {
    "id": "MUSTANG2",
    "name": "MUSTANG2"
  },
  {
    "id": "PANTHER1",
    "name": "PANTHER1"
  },
  {
    "id": "PANTHER2",
    "name": "PANTHER2"
  },
  {
    "id": "PAW1",
    "name": "PAW1"
  },
  {
    "id": "PAW2",
    "name": "PAW2"
  },
  {
    "id": "PAW3",
    "name": "PAW3"
  },
  {
    "id": "PAW4",
    "name": "PAW4"
  },
  {
    "id": "PAW5",
    "name": "PAW5"
  },
  {
    "id": "PAW6",
    "name": "PAW6"
  },
  {
    "id": "PAW7",
    "name": "PAW7"
  },
  {
    "id": "PAW8",
    "name": "PAW8"
  },
  {
    "id": "PAW9",
    "name": "PAW9"
  },
  {
    "id": "PAW10",
    "name": "PAW10"
  },
  {
    "id": "PAW11",
    "name": "PAW11"
  },
  {
    "id": "PAW12",
    "name": "PAW12"
  },
  {
    "id": "PAW13",
    "name": "PAW13"
  },
  {
    "id": "PAW14",
    "name": "PAW14"
  },
  {
    "id": "PAW15",
    "name": "PAW15"
  },
  {
    "id": "PAW16",
    "name": "PAW16"
  },
  {
    "id": "PAW17",
    "name": "PAW17"
  },
  {
    "id": "PAW18",
    "name": "PAW18"
  },
  {
    "id": "PIRATE1",
    "name": "PIRATE1"
  },
  {
    "id": "PIRATE2",
    "name": "PIRATE2"
  },
  {
    "id": "PIRATE3",
    "name": "PIRATE3"
  },
  {
    "id": "PIRATE4",
    "name": "PIRATE4"
  },
  {
    "id": "PIRATE5",
    "name": "PIRATE5"
  },
  {
    "id": "PIRATE6",
    "name": "PIRATE6"
  },
  {
    "id": "PIRATE7",
    "name": "PIRATE7"
  },
  {
    "id": "PIRATE8",
    "name": "PIRATE8"
  },
  {
    "id": "PIRATE9",
    "name": "PIRATE9"
  },
  {
    "id": "PIRATE10",
    "name": "PIRATE10"
  },
  {
    "id": "PIRATE11",
    "name": "PIRATE11"
  },
  {
    "id": "PIRATE12",
    "name": "PIRATE12"
  },
  {
    "id": "PIRATE13",
    "name": "PIRATE13"
  },
  {
    "id": "RAM1",
    "name": "RAM1"
  },
  {
    "id": "RAM2",
    "name": "RAM2"
  },
  {
    "id": "REBEL",
    "name": "REBEL"
  },
  {
    "id": "SHAMROCK1",
    "name": "SHAMROCK1"
  },
  {
    "id": "SHAMROCK2",
    "name": "SHAMROCK2"
  },
  {
    "id": "SHARK1",
    "name": "SHARK1"
  },
  {
    "id": "SNAKE1",
    "name": "SNAKE1"
  },
  {
    "id": "SNAKE2",
    "name": "SNAKE2"
  },
  {
    "id": "SNAKE3",
    "name": "SNAKE3"
  },
  {
    "id": "SPARTAN1",
    "name": "SPARTAN1"
  },
  {
    "id": "SPARTAN2",
    "name": "SPARTAN2"
  },
  {
    "id": "SPARTAN3",
    "name": "SPARTAN3"
  },
  {
    "id": "SPARTAN4",
    "name": "SPARTAN4"
  },
  {
    "id": "SPARTAN5",
    "name": "SPARTAN5"
  },
  {
    "id": "SPARTAN6",
    "name": "SPARTAN6"
  },
  {
    "id": "SPARTAN7",
    "name": "SPARTAN7"
  },
  {
    "id": "SPARTAN8",
    "name": "SPARTAN8"
  },
  {
    "id": "SPARTAN9",
    "name": "SPARTAN9"
  },
  {
    "id": "SPARTAN10",
    "name": "SPARTAN10"
  },
  {
    "id": "SPARTAN11",
    "name": "SPARTAN11"
  },
  {
    "id": "SPARTAN12",
    "name": "SPARTAN12"
  },
  {
    "id": "SPARTAN13",
    "name": "SPARTAN13"
  },
  {
    "id": "SPARTAN14",
    "name": "SPARTAN14"
  },
  {
    "id": "STAR1",
    "name": "STAR1"
  },
  {
    "id": "STAR2",
    "name": "STAR2"
  },
  {
    "id": "STAR3",
    "name": "STAR3"
  },
  {
    "id": "STAR4",
    "name": "STAR4"
  },
  {
    "id": "STAR5",
    "name": "STAR5"
  },
  {
    "id": "TIGER1",
    "name": "TIGER1"
  },
  {
    "id": "TIGER2",
    "name": "TIGER2"
  },
  {
    "id": "TIGER3",
    "name": "TIGER3"
  },
  {
    "id": "VIKING1",
    "name": "VIKING1"
  },
  {
    "id": "VIKING2",
    "name": "VIKING2"
  },
  {
    "id": "VIKING3",
    "name": "VIKING3"
  },
  {
    "id": "VIKING4",
    "name": "VIKING4"
  },
  {
    "id": "VIKING5",
    "name": "VIKING5"
  },
  {
    "id": "VIKING6",
    "name": "VIKING6"
  },
  {
    "id": "WILDCAT1",
    "name": "WILDCAT1"
  },
  {
    "id": "WILDCAT2",
    "name": "WILDCAT2"
  },
  {
    "id": "WILDCAT3",
    "name": "WILDCAT3"
  },
  {
    "id": "WOLF",
    "name": "WOLF"
  },
  {
    "id": "WOLF1",
    "name": "WOLF1"
  },
  {
    "id": "WOLF2",
    "name": "WOLF2"
  },
  {
    "id": "WOLF3",
    "name": "WOLF3"
  },
  {
    "id": "WOLF4",
    "name": "WOLF4"
  },
  {
    "id": "WOLF5",
    "name": "WOLF5"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<StockLogoRow[]>> = data;

export type StockLogo = typeof typed[number];

export const STOCK_LOGO_INDEX_KEY = "id";
export type StockLogoIndexKey = "id";
export type StockLogoId = StockLogo["id"];

let i = 0;
export const STOCK_LOGO_DICT = {
  "abc": typed[i++],
  "graduation": typed[i++],
  "match": typed[i++],
  "happyBirthday": typed[i++],
  "happyFatherSDay": typed[i++],
  "bells": typed[i++],
  "happyMotherSDay": typed[i++],
  "engagement": typed[i++],
  "itSABoy": typed[i++],
  "itSAGirl": typed[i++],
  "stork": typed[i++],
  "USFLAG": typed[i++],
  "7ON7": typed[i++],
  "BASEBALL1": typed[i++],
  "BASEBALL2": typed[i++],
  "BASEBALL3": typed[i++],
  "BASEBALL4": typed[i++],
  "BASEBALL5": typed[i++],
  "BASEBALL6": typed[i++],
  "BASKETBALL1": typed[i++],
  "BASKETBALL2": typed[i++],
  "BASKETBALL3": typed[i++],
  "BASKETBALL4": typed[i++],
  "BEAR1": typed[i++],
  "BEAR2": typed[i++],
  "BEAR3": typed[i++],
  "BEAR4": typed[i++],
  "BEAR5": typed[i++],
  "BEAR6": typed[i++],
  "BIRD1": typed[i++],
  "BIRD2": typed[i++],
  "BIRD3": typed[i++],
  "BIRD4": typed[i++],
  "BIRD5": typed[i++],
  "BIRD6": typed[i++],
  "BIRD7": typed[i++],
  "BIRD8": typed[i++],
  "BIRD9": typed[i++],
  "BIRD10": typed[i++],
  "BIRD11": typed[i++],
  "BIRD12": typed[i++],
  "BIRD13": typed[i++],
  "BIRD14": typed[i++],
  "BIRD15": typed[i++],
  "BIRD16": typed[i++],
  "BIRD17": typed[i++],
  "BIRD18": typed[i++],
  "BIRD19": typed[i++],
  "BIRD20": typed[i++],
  "BIRD21": typed[i++],
  "BIRD22": typed[i++],
  "BIRD23": typed[i++],
  "BIRD24": typed[i++],
  "BIRD25": typed[i++],
  "BIRD26": typed[i++],
  "BIRD27": typed[i++],
  "BIRD28": typed[i++],
  "BIRD29": typed[i++],
  "BIRD30": typed[i++],
  "BRAVE1": typed[i++],
  "BRAVE2": typed[i++],
  "BUCCANEER": typed[i++],
  "BUFFALO": typed[i++],
  "BULLDOG1": typed[i++],
  "BULLDOG2": typed[i++],
  "BULLDOG3": typed[i++],
  "BULLDOG4": typed[i++],
  "BULLDOG5": typed[i++],
  "BULLDOG6": typed[i++],
  "BULLDOG7": typed[i++],
  "BULLDOG8": typed[i++],
  "BULLDOG9": typed[i++],
  "BULLDOG10": typed[i++],
  "CARDINAL1": typed[i++],
  "CARDINAL2": typed[i++],
  "CAT1": typed[i++],
  "CAT2": typed[i++],
  "CAT3": typed[i++],
  "CAT4": typed[i++],
  "CAT5": typed[i++],
  "CAT6": typed[i++],
  "CAT7": typed[i++],
  "CAT8": typed[i++],
  "CAT9": typed[i++],
  "CAT10": typed[i++],
  "CAT11": typed[i++],
  "CAT12": typed[i++],
  "CAT13": typed[i++],
  "CAT14": typed[i++],
  "CAT15": typed[i++],
  "CAT16": typed[i++],
  "CAT17": typed[i++],
  "CAT18": typed[i++],
  "CAT19": typed[i++],
  "CAVALIER1": typed[i++],
  "CAVALIER2": typed[i++],
  "CAVALIER3": typed[i++],
  "CHIEF": typed[i++],
  "COBRA": typed[i++],
  "COMICTROJAN": typed[i++],
  "COUGAR": typed[i++],
  "DEVIL": typed[i++],
  "DEVIL2": typed[i++],
  "DEVIL3": typed[i++],
  "DEVIL4": typed[i++],
  "DEVIL5": typed[i++],
  "DRAGON": typed[i++],
  "DRAGONH13": typed[i++],
  "DRAGONI13": typed[i++],
  "EAGLE1": typed[i++],
  "EAGLE2": typed[i++],
  "EAGLE3": typed[i++],
  "EAGLE4": typed[i++],
  "EAGLE5": typed[i++],
  "FOOTBALL1": typed[i++],
  "FOOTBALL2": typed[i++],
  "FOOTBALL3": typed[i++],
  "FOOTBALL4": typed[i++],
  "FOOTBALL5": typed[i++],
  "HELMET": typed[i++],
  "HORSE1": typed[i++],
  "HORSE10": typed[i++],
  "HORSE2": typed[i++],
  "HORSE3": typed[i++],
  "HORSE4": typed[i++],
  "HORSE5": typed[i++],
  "HORSE6": typed[i++],
  "HORSE7": typed[i++],
  "HORSE8": typed[i++],
  "HORSE9": typed[i++],
  "HUSKYF1": typed[i++],
  "INDIAN1": typed[i++],
  "INDIAN2": typed[i++],
  "INDIAN3": typed[i++],
  "INDIAN4": typed[i++],
  "INDIAN5": typed[i++],
  "INDIAN6": typed[i++],
  "INDIAN7": typed[i++],
  "INDIAN8": typed[i++],
  "INDIAN9": typed[i++],
  "INDIAN10": typed[i++],
  "INDIAN11": typed[i++],
  "INDIAN12": typed[i++],
  "INDIAN13": typed[i++],
  "INDIAN14": typed[i++],
  "INDIAN15": typed[i++],
  "INSECT1": typed[i++],
  "INSECT2": typed[i++],
  "INSECT3": typed[i++],
  "INSECT4": typed[i++],
  "INSECT5": typed[i++],
  "KNIGHT1": typed[i++],
  "KNIGHT2": typed[i++],
  "KNIGHT3": typed[i++],
  "KNIGHT4": typed[i++],
  "KNIGHT5": typed[i++],
  "KNIGHT6": typed[i++],
  "KNIGHT7": typed[i++],
  "KNIGHT8": typed[i++],
  "KNIGHT9": typed[i++],
  "KNIGHT10": typed[i++],
  "LIGHTNING1": typed[i++],
  "LIGHTNING2": typed[i++],
  "LIGHTNING3": typed[i++],
  "LIGHTNING4": typed[i++],
  "LION1": typed[i++],
  "LION2": typed[i++],
  "LION3": typed[i++],
  "LION4": typed[i++],
  "LION5": typed[i++],
  "LION6": typed[i++],
  "LION7": typed[i++],
  "LION8": typed[i++],
  "LONGHORNS": typed[i++],
  "MARLIN": typed[i++],
  "MISC1": typed[i++],
  "MISC2": typed[i++],
  "MISC3": typed[i++],
  "MISC4": typed[i++],
  "MISC5": typed[i++],
  "MISC6": typed[i++],
  "MISC7": typed[i++],
  "MISC8": typed[i++],
  "MISC9": typed[i++],
  "MISC10": typed[i++],
  "MISC11": typed[i++],
  "MISC12": typed[i++],
  "MISC13": typed[i++],
  "MISC14": typed[i++],
  "MISC15": typed[i++],
  "MISC16": typed[i++],
  "MISC17": typed[i++],
  "MISC18": typed[i++],
  "MISC19": typed[i++],
  "MISC20": typed[i++],
  "MISC21": typed[i++],
  "MISC22": typed[i++],
  "MISC23": typed[i++],
  "MUSTANG1": typed[i++],
  "MUSTANG2": typed[i++],
  "PANTHER1": typed[i++],
  "PANTHER2": typed[i++],
  "PAW1": typed[i++],
  "PAW2": typed[i++],
  "PAW3": typed[i++],
  "PAW4": typed[i++],
  "PAW5": typed[i++],
  "PAW6": typed[i++],
  "PAW7": typed[i++],
  "PAW8": typed[i++],
  "PAW9": typed[i++],
  "PAW10": typed[i++],
  "PAW11": typed[i++],
  "PAW12": typed[i++],
  "PAW13": typed[i++],
  "PAW14": typed[i++],
  "PAW15": typed[i++],
  "PAW16": typed[i++],
  "PAW17": typed[i++],
  "PAW18": typed[i++],
  "PIRATE1": typed[i++],
  "PIRATE2": typed[i++],
  "PIRATE3": typed[i++],
  "PIRATE4": typed[i++],
  "PIRATE5": typed[i++],
  "PIRATE6": typed[i++],
  "PIRATE7": typed[i++],
  "PIRATE8": typed[i++],
  "PIRATE9": typed[i++],
  "PIRATE10": typed[i++],
  "PIRATE11": typed[i++],
  "PIRATE12": typed[i++],
  "PIRATE13": typed[i++],
  "RAM1": typed[i++],
  "RAM2": typed[i++],
  "REBEL": typed[i++],
  "SHAMROCK1": typed[i++],
  "SHAMROCK2": typed[i++],
  "SHARK1": typed[i++],
  "SNAKE1": typed[i++],
  "SNAKE2": typed[i++],
  "SNAKE3": typed[i++],
  "SPARTAN1": typed[i++],
  "SPARTAN2": typed[i++],
  "SPARTAN3": typed[i++],
  "SPARTAN4": typed[i++],
  "SPARTAN5": typed[i++],
  "SPARTAN6": typed[i++],
  "SPARTAN7": typed[i++],
  "SPARTAN8": typed[i++],
  "SPARTAN9": typed[i++],
  "SPARTAN10": typed[i++],
  "SPARTAN11": typed[i++],
  "SPARTAN12": typed[i++],
  "SPARTAN13": typed[i++],
  "SPARTAN14": typed[i++],
  "STAR1": typed[i++],
  "STAR2": typed[i++],
  "STAR3": typed[i++],
  "STAR4": typed[i++],
  "STAR5": typed[i++],
  "TIGER1": typed[i++],
  "TIGER2": typed[i++],
  "TIGER3": typed[i++],
  "VIKING1": typed[i++],
  "VIKING2": typed[i++],
  "VIKING3": typed[i++],
  "VIKING4": typed[i++],
  "VIKING5": typed[i++],
  "VIKING6": typed[i++],
  "WILDCAT1": typed[i++],
  "WILDCAT2": typed[i++],
  "WILDCAT3": typed[i++],
  "WOLF": typed[i++],
  "WOLF1": typed[i++],
  "WOLF2": typed[i++],
  "WOLF3": typed[i++],
  "WOLF4": typed[i++],
  "WOLF5": typed[i++]
} as const;

export { typed as STOCK_LOGO };
