// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SurfaceFinishRow } from '../typings';

const data = [
  {
    "id": "matte",
    "name": "Matte",
    "props": {
      "surfaceMaterialId": "matteCoat"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": true,
        "customAwardBat": true,
        "customMiniBat": true
      }
    }
  },
  {
    "id": "highGloss",
    "name": "High Gloss",
    "props": {
      "surfaceMaterialId": "highGlossCoat"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": true,
        "customFungoBat": true,
        "personalizedAwardBat": false,
        "customAwardBat": true,
        "customMiniBat": true
      }
    }
  },
  {
    "id": "satin",
    "name": "Satin",
    "props": {
      "surfaceMaterialId": "satinCoat"
    },
    "subsets": {
      "categoryId": {
        "customGameUseBat": false,
        "customFungoBat": false,
        "personalizedAwardBat": false,
        "customAwardBat": false,
        "customMiniBat": false
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SurfaceFinishRow[]>> = data;

export type SurfaceFinish = typeof typed[number];

export const SURFACE_FINISH_INDEX_KEY = "id";
export type SurfaceFinishIndexKey = "id";
export type SurfaceFinishId = SurfaceFinish["id"];

let i = 0;
export const SURFACE_FINISH_DICT = {
  "matte": typed[i++],
  "highGloss": typed[i++],
  "satin": typed[i++]
} as const;

export { typed as SURFACE_FINISH };
