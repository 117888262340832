import React from 'react'
import { Provider, connect } from 'react-redux'
import WebFont from 'webfontloader'
import { compact } from '@technically/lodash'
import * as changeCase from 'change-case'

import Layout from '~rawlings/client/components/Layout'

import '~rawlings/client/components/Main.css'

import Render3d from '../../render3d/containers/Render3d'
import EndSectionTexts from '../components/EndSectionTexts'

import controlTree from '../controlTree'
import { MODEL_ATTRIBUTE_DICT, SURFACE_TYPE_DICT } from '../../common/sheets'

import './Overrides.css'
import './TileCard.css'
import { layoutModeSelector } from '~p/client/common/selectors'

WebFont.load({
  google: {
    families: ['Open Sans:300,400,600,700'],
  },
})

const customComponents = {
  EndSectionTexts,
}

const mapStateToProps = (state) => ({
  nodes: controlTree.getNodes(state),
  layoutMode: layoutModeSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  change: (path, value) => dispatch(controlTree.change(path, value)),
})

const getProductInfo = (nodes, recipeId) => {
  let title = nodes['filter.category'].optionName
  if (nodes['product.model'].value !== nodes['filter.category'].value) {
    const detailParts = compact([
      nodes['product.woodSpecies'].options.length > 1 &&
        nodes['product.woodSpecies'].optionName,
      nodes['filter.series'].optionName,
      nodes['product.length'].optionName,
    ])
    title = `${nodes['calc.skuLabel'].value} - ${detailParts.join(', ')}`
  }

  return {
    summarySections: [
      {
        id: 'product',
        label: 'Product',
        details: [],
      },
    ],
    title,
    priceWithCurrency: nodes['calc.priceWithCurrency'].value,
    configurationId: recipeId,
  }
}

const getTileExtras = (option) => {
  if (!option.props.attributes) {
    return
  }

  return Object.entries(option.props.attributes)
    .filter(([, score]) => !Number.isNaN(score))
    .map(([name, score]) => {
      const attribute = MODEL_ATTRIBUTE_DICT[name]

      return (
        <div key={name} className="tileCard-attribute">
          <span className="tileCard-attributeName">{attribute.name}</span>
          <div className="tileCard-scoreTrack">
            <div className="tileCard-scoreBar" style={{ width: `${score}%` }} />
          </div>
          <div className="tileCard-scoreLabels">
            {attribute.rangeLabels.map((label) => (
              <span>{label}</span>
            ))}
          </div>
        </div>
      )
    })
}

const ConnectedLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props) => (
  <Layout
    Renderer={<Render3d />}
    getProductInfo={getProductInfo}
    customComponents={customComponents}
    onChangePost={(path, value) => {
      const BARREL_TYPE_PATH = 'colors.barrel.type'
      const HANDLE_TYPE_PATH = 'colors.handle.type'

      if (path !== BARREL_TYPE_PATH && path !== HANDLE_TYPE_PATH) return

      const flameTarSurfaceId = SURFACE_TYPE_DICT['flame-tar'].id
      const naturalSurfaceId = SURFACE_TYPE_DICT['natural'].id
      const oppositePath =
        path === BARREL_TYPE_PATH ? HANDLE_TYPE_PATH : BARREL_TYPE_PATH

      if (value === flameTarSurfaceId) {
        props.change(oppositePath, flameTarSurfaceId)
      } else if (props.nodes[oppositePath]?.value === flameTarSurfaceId) {
        props.change(oppositePath, naturalSurfaceId)
      }
    }}
    getTileExtras={getTileExtras}
    isScrollMenuLayout={props.layoutMode !== 'mobile'}
  />
))

const Root = ({ store }) => (
  <Provider store={store}>
    <ConnectedLayout />
  </Provider>
)

export default Root
