import Bluebird from 'bluebird'
import { mapValues, omitBy, isUndefined } from '@technically/lodash'

import AbstractLoader, {
  updateSingleLoader,
  updateLoaderMaps,
} from '~p/client/renderSvg/AbstractLoader'
import DsvgTextureBuilder from '~p/client/renderSvg/DsvgTextureBuilder'

import { loadTexture, loadCubeTexture, loadMeshes } from './dataLoaders'

const getUtils = (scene) => {
  const _scene = scene
  const _dsvgBuilders = new Map()

  const _ensureDsvgBuilder = (key) => {
    let builder = _dsvgBuilders.get(key)
    if (builder == null) {
      builder = new DsvgTextureBuilder()
      _dsvgBuilders.set(key, builder)
    }
    return builder
  }

  function wrap(url, p) {
    p.url = url
    return p
  }

  const _loadMeshes = (url) => wrap(url, loadMeshes(url, _scene))

  const _loadTexture = (source) => {
    if (typeof source === 'string') {
      return wrap(source, loadTexture(source))
    }

    const builder = _ensureDsvgBuilder(source.name)
    return builder.load(source)
  }

  const _loadCubeTexture = (url) => wrap(url, loadCubeTexture(url, _scene))

  const _createAssetLoaders = (def) =>
    omitBy(
      {
        hdr: def.hdr ? _loadCubeTexture(def.hdr) : undefined,
        meshes: def.meshes ? _loadMeshes(def.meshes) : undefined,
        textures:
          def.textures ? mapValues(def.textures, _loadTexture) : undefined,
      },
      isUndefined,
    )

  const _updateAssetLoaders = (prev, next, loaders) => {
    if (prev == null) {
      return _createAssetLoaders(next)
    }
    return omitBy(
      {
        hdr:
          next.hdr ?
            updateSingleLoader(
              prev.hdr || '',
              next.hdr,
              loaders.hdr,
              _loadCubeTexture,
            )
          : undefined,
        meshes:
          next.meshes ?
            updateSingleLoader(
              prev.meshes || '',
              next.meshes,
              loaders.meshes,
              _loadMeshes,
            )
          : undefined,
        textures:
          next.textures ?
            updateLoaderMaps(
              prev.textures || {},
              next.textures,
              loaders.textures || {},
              _loadTexture,
            )
          : undefined,
      },
      isUndefined,
    )
  }

  const _combineAssetLoaders = (loaders) =>
    new Bluebird((resolve, reject, onCancel) => {
      onCancel(() => {
        // NO-OP - internal promises will be reused
      })

      return Bluebird.props(
        omitBy(
          {
            hdr: loaders.hdr,
            meshes: loaders.meshes,
            textures:
              loaders.textures ? Bluebird.props(loaders.textures) : undefined,
          },
          isUndefined,
        ),
      ).then(resolve, reject)
    })

  const createLoaders = (def) => ({
    scene: _createAssetLoaders(def.scene),
    models: mapValues(def.models, _createAssetLoaders),
  })

  const updateLoaders = (prev, next, loaders) => ({
    scene: _updateAssetLoaders(prev.scene, next.scene, loaders.scene),
    models: mapValues(next.models, (nextModel, key) =>
      _updateAssetLoaders(prev.models[key], nextModel, loaders.models[key]),
    ),
  })

  const combineLoaders = (loaders) =>
    Bluebird.props({
      scene: _combineAssetLoaders(loaders.scene),
      models: Bluebird.props(mapValues(loaders.models, _combineAssetLoaders)),
    })

  return {
    createLoaders,
    updateLoaders,
    combineLoaders,
  }
}

export default class DsvgSceneLoader extends AbstractLoader {
  constructor(scene) {
    super(getUtils(scene))
  }
}
