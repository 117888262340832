import { Engine } from '@babylonjs/core'

const createEngine = (canvas) => {
  const engine = new Engine(canvas, true, {}, true)

  engine.enableOfflineSupport = false

  return engine
}

export default createEngine
