import Bluebird from 'bluebird'
import { Texture, CubeTexture, SceneLoader } from '@babylonjs/core'
import { keyBy } from '@technically/lodash'

export const loadTexture = (url) =>
  new Bluebird((resolve, reject, onCancel) => {
    const texture = new Texture(
      url,
      null,
      null,
      true,
      null,
      () => {
        resolve(texture)
      },
      (message) => {
        reject(new Error(`Can not load texture: ${url}, message: ${message}`))
      },
    )
    onCancel(() => {
      texture.dispose()
    })
  })

export const loadCubeTexture = (url, scene) =>
  new Bluebird((resolve, reject, onCancel) => {
    const texture = new CubeTexture(
      url,
      scene,
      null,
      null,
      null,
      () => {
        resolve(texture)
      },
      (message) => {
        reject(
          new Error(`Can not load cube texture: ${url}, message: ${message}`),
        )
      },
    )
    onCancel(() => {
      texture.dispose()
    })
  })

export const loadMeshes = (url, scene) => {
  const index = url.lastIndexOf('/')
  const rootUrl = url.slice(0, index + 1)
  const sceneFilename = url.slice(index + 1)

  return new Bluebird((resolve, reject, onCancel) => {
    let isCancelled = false
    SceneLoader.LoadAssetContainer(
      rootUrl,
      sceneFilename,
      scene,
      (assets) => {
        if (isCancelled) {
          assets.dispose()
        }

        const meshes = keyBy(assets.meshes, 'id')
        resolve(meshes)
      },
      null,
      (_, message) => {
        reject(new Error(`Can not load meshes: ${url}, message: ${message}`))
      },
    )
    onCancel(() => {
      isCancelled = true
    })
  })
}
