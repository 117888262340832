// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { WoodSpecieRow } from '../typings';

const data = [
  {
    "id": "maple",
    "name": "Maple",
    "description": "High Density, Hardest Wood, Heavier Swing Weight, #1 Choice of MLB Players",
    "props": {
      "assetId": "maple"
    }
  },
  {
    "id": "ash",
    "name": "Ash",
    "description": "Porous, Lightweight, Strongest Timber, Flex and Forgiveness, Low Density, Softer Wood",
    "props": {
      "assetId": "ash"
    }
  },
  {
    "id": "birch",
    "name": "Birch",
    "description": "Medium Density, Medium Swing Weight, Medium Flex and Forgiveness",
    "props": {
      "assetId": "maple"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<WoodSpecieRow[]>> = data;

export type WoodSpecie = typeof typed[number];

export const WOOD_SPECIE_INDEX_KEY = "id";
export type WoodSpecieIndexKey = "id";
export type WoodSpecieId = WoodSpecie["id"];

let i = 0;
export const WOOD_SPECIE_DICT = {
  "maple": typed[i++],
  "ash": typed[i++],
  "birch": typed[i++]
} as const;

export { typed as WOOD_SPECIES };
