import { Camera } from '@babylonjs/core'

const updateCamera = (camera, cameraDef) => {
  camera.alpha = cameraDef.alpha
  camera.beta = cameraDef.beta
  camera.radius = cameraDef.radius
  camera.minZ = 0.1
  camera.maxZ = 10
  camera.wheelPrecision = 160
  camera.upperRadiusLimit = Math.max(2, cameraDef.radius)
  camera.lowerRadiusLimit = Math.min(0.5, cameraDef.radius)
  camera.fov = cameraDef.fov
  camera.fovMode = Camera.FOVMODE_HORIZONTAL_FIXED

  return camera
}

export default updateCamera
