// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { ModelRow } from '../typings';

const data = [
  {
    "id": "MM13",
    "name": "MM13",
    "description": "Game day model of Manny Machado. Balanced profile",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "player",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile5",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 80,
        "density": 50,
        "swingWeight": 80
      }
    }
  },
  {
    "id": "BH3",
    "name": "BH3",
    "description": "Game day model of Bryce Harper. Balanced profile",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "player",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile1",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 70,
        "density": 70,
        "swingWeight": 50
      }
    }
  },
  {
    "id": "OA1",
    "name": "OA1",
    "description": "Game day model of Ozzie Albeis. Balanced profile",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "player",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile2",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 80,
        "density": 50,
        "swingWeight": 60
      }
    }
  },
  {
    "id": "110",
    "name": "110",
    "description": "Balanced bat for player looking for a fast, balanced stick",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "velo",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile4",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 80,
        "density": 60,
        "swingWeight": 50
      }
    }
  },
  {
    "id": "243",
    "name": "243-M",
    "description": "Larger hitting surface and increased sweet spot for player looking for heavier barrel for max power at the plate",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "bigStick",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile1",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 80,
        "density": 60,
        "swingWeight": 40
      }
    }
  },
  {
    "id": "271ML",
    "name": "271ML",
    "description": "Balanced bat for player looking for a fast, balanced stick",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "velo",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile4",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 90,
        "density": 50,
        "swingWeight": 40
      }
    }
  },
  {
    "id": "I13M",
    "name": "I13M",
    "description": "Larger hitting surface and increased sweet spot for player looking for heavier barrel for max power at the plate",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "bigStick",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile2",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 90,
        "density": 50,
        "swingWeight": 40
      }
    }
  },
  {
    "id": "MT27",
    "name": "MT27",
    "description": "Lorem ipsum dolor sit amet",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "velo",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile1",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 80,
        "density": 50,
        "swingWeight": 20
      }
    }
  },
  {
    "id": "RA13",
    "name": "RA13",
    "description": "Larger hitting surface and increased sweet spot for player looking for heavier barrel for max power at the plate",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "bigStick",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile5",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 80,
        "density": 50,
        "swingWeight": 70
      }
    }
  },
  {
    "id": "AP5",
    "name": "AP5",
    "description": "Lorem ipsum dolor sit amet",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "player",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile2",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 100,
        "density": 20,
        "swingWeight": 50
      }
    }
  },
  {
    "id": "CS5",
    "name": "CS5",
    "description": "Lorem ipsum dolor sit amet",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "bigStick",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile3",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 70,
        "density": 80,
        "swingWeight": 40
      }
    }
  },
  {
    "id": "MT456",
    "name": "MT456",
    "description": "Lorem ipsum dolor sit amet",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "player",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 199.99,
        "birch": 199.99,
        "maple": 199.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile1",
      "meshScale": 1,
      "attributes": {
        "sweetSpot": 90,
        "density": 40,
        "swingWeight": 20
      }
    }
  },
  {
    "id": "151",
    "name": "151",
    "description": "Lorem ipsum dolor sit amet, ut pri viderer legimus comprehensam.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "bigStick",
      "isEnabled": false,
      "basePriceByWoodSpecies": {
        "ash": 79.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile1",
      "meshScale": 1
    }
  },
  {
    "id": "Y62",
    "name": "Y62",
    "description": "Lorem ipsum dolor sit amet, ut pri viderer legimus comprehensam.",
    "props": {
      "categoryId": "customGameUseBat",
      "seriesId": "velo",
      "isEnabled": false,
      "basePriceByWoodSpecies": {
        "ash": 79.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile4",
      "meshScale": 1
    }
  },
  {
    "id": "customFungoBat",
    "name": "114",
    "props": {
      "categoryId": "customFungoBat",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 79.99
      },
      "viewDefGroupId": "fungo",
      "assetId": "profile6",
      "meshScale": 1
    }
  },
  {
    "id": "customMiniBat",
    "name": "MB",
    "props": {
      "categoryId": "customMiniBat",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 24.99
      },
      "viewDefGroupId": "mini",
      "assetId": "profile7",
      "meshScale": 2
    }
  },
  {
    "id": "personalizedAwardBat",
    "name": "232",
    "props": {
      "categoryId": "personalizedAwardBat",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 59.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile8",
      "meshScale": 1
    }
  },
  {
    "id": "customAwardBat",
    "name": "232",
    "props": {
      "categoryId": "customAwardBat",
      "isEnabled": true,
      "basePriceByWoodSpecies": {
        "ash": 59.99
      },
      "viewDefGroupId": "custom",
      "assetId": "profile1",
      "meshScale": 1
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<ModelRow[]>> = data;

export type Model = typeof typed[number];

export const MODEL_INDEX_KEY = "id";
export type ModelIndexKey = "id";
export type ModelId = Model["id"];

let i = 0;
export const MODEL_DICT = {
  "MM13": typed[i++],
  "BH3": typed[i++],
  "OA1": typed[i++],
  "110": typed[i++],
  "243": typed[i++],
  "271ML": typed[i++],
  "I13M": typed[i++],
  "MT27": typed[i++],
  "RA13": typed[i++],
  "AP5": typed[i++],
  "CS5": typed[i++],
  "MT456": typed[i++],
  "151": typed[i++],
  "Y62": typed[i++],
  "customFungoBat": typed[i++],
  "customMiniBat": typed[i++],
  "personalizedAwardBat": typed[i++],
  "customAwardBat": typed[i++]
} as const;

export { typed as MODEL };
