import { pickBy, identity } from '@technically/lodash'

import getAsset from '~p/getAsset'

import * as batSvgConfig from '../renderSvg/batSvgConfig'
import { expandValues } from '../common/dataUtils'
import { CUPPED_END_DICT, KNOB_STYLE_DICT } from '../common/sheets'

const getSceneDef = (data) => {
  if (!data.product) {
    return {
      scene: {},
      models: {},
    }
  }

  const { assetId, meshScale } = data.product.model.props
  const woodAssetId = data.product.woodSpecies.props.assetId
  const gripTape = data.options && data.options.gripTape
  const size = { id: '2k', width: 2048, height: 2048 }

  const cuppedEndMeshMap = Object.keys(CUPPED_END_DICT).reduce((acc, key) => {
    acc[key === 'none' ? key : `end-${key}_${assetId}`] =
      CUPPED_END_DICT[key].id === (data.options?.cuppedEnd?.id ?? 'none')
    return acc
  }, {})

  const puckKnobMeshMap = Object.keys(KNOB_STYLE_DICT).reduce((acc, key) => {
    acc[`puck-${key}_${assetId}`] =
      KNOB_STYLE_DICT[key].id === data.options?.knobStyle?.id
    return acc
  }, {})

  const safeData = expandValues(data)
  return {
    scene: {
      hdr: getAsset('studioSpecularHDR.dds'),
    },
    models: pickBy(
      {
        bat: {
          meshes: getAsset(`bats/${assetId}/${assetId}.babylon`),
          scale: meshScale,
          meshVisibility: {
            base: true,
            [`end-regular_${assetId}`]: cuppedEndMeshMap.none,
            ...cuppedEndMeshMap,
            ...puckKnobMeshMap,
          },
          textures: {
            normal: getAsset(
              `bats/${assetId}/${assetId}_${woodAssetId}_normals.png`,
            ),
            diffuse: batSvgConfig.getDiffuseDef(safeData, size),
            roughness: batSvgConfig.getRoughnessDef(safeData, size),
          },
        },
        gripTape: gripTape && {
          meshes: getAsset(`bats/${assetId}/${assetId}-grip.babylon`),
          scale: meshScale,
          textures: {
            diffuse: getAsset(`bats/gripDiffuse/${gripTape.id}.png`),
            roughness: getAsset(`bats/gripMetallicRoughness.png`),
          },
        },
      },
      identity,
    ),
  }
}

export default getSceneDef
