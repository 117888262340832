// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SurfaceMaterialRow } from '../typings';

const data = [
  {
    "id": "unfinished",
    "name": "Unfinished",
    "props": {
      "metallicRoughness": "#FFB200"
    }
  },
  {
    "id": "matteCoat",
    "name": "Matte Coat",
    "props": {
      "metallicRoughness": "#FF4C00"
    }
  },
  {
    "id": "satinCoat",
    "name": "Satin Coat",
    "props": {
      "metallicRoughness": "#FF4C00"
    }
  },
  {
    "id": "highGlossCoat",
    "name": "High Gloss Coat",
    "props": {
      "metallicRoughness": "#FF1C00"
    }
  },
  {
    "id": "foilSticker",
    "name": "Foil Sticker",
    "props": {
      "metallicRoughness": "#FF3AFF"
    }
  },
  {
    "id": "vinylSticker",
    "name": "Vinyl Sticker",
    "props": {
      "metallicRoughness": "#FF1C00"
    }
  },
  {
    "id": "syntheticGripTape",
    "name": "Synthetic Grip Tape",
    "props": {
      "metallicRoughness": "#FF3300"
    }
  },
  {
    "id": "gripEnd",
    "name": "Grip End",
    "props": {
      "metallicRoughness": "#FF2B00"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SurfaceMaterialRow[]>> = data;

export type SurfaceMaterial = typeof typed[number];

export const SURFACE_MATERIAL_INDEX_KEY = "id";
export type SurfaceMaterialIndexKey = "id";
export type SurfaceMaterialId = SurfaceMaterial["id"];

let i = 0;
export const SURFACE_MATERIAL_DICT = {
  "unfinished": typed[i++],
  "matteCoat": typed[i++],
  "satinCoat": typed[i++],
  "highGlossCoat": typed[i++],
  "foilSticker": typed[i++],
  "vinylSticker": typed[i++],
  "syntheticGripTape": typed[i++],
  "gripEnd": typed[i++]
} as const;

export { typed as SURFACE_MATERIAL };
