// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { SerieRow } from '../typings';

const data = [
  {
    "id": "bigStick",
    "name": "Big Stick",
    "description": "Just that, a large barrel that carries a more end loaded swing weight."
  },
  {
    "id": "velo",
    "name": "Velo",
    "description": "A more balanced design built for generating bat speed."
  },
  {
    "id": "player",
    "name": "Player Models",
    "description": "Swing the same stick designed by your favorite Pros."
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<SerieRow[]>> = data;

export type Serie = typeof typed[number];

export const SERIE_INDEX_KEY = "id";
export type SerieIndexKey = "id";
export type SerieId = Serie["id"];

let i = 0;
export const SERIE_DICT = {
  "bigStick": typed[i++],
  "velo": typed[i++],
  "player": typed[i++]
} as const;

export { typed as SERIES };
