// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { CuppedEndRow } from '../typings';

const data = [
  {
    "id": "none",
    "name": "None"
  },
  {
    "id": "0_5inch",
    "name": "½\"",
    "description": "Decreases bat weight by 0.3 oz"
  },
  {
    "id": "1inch",
    "name": "1\"",
    "description": "Decreases bat weight by 0.75 oz"
  },
  {
    "id": "1_25inch",
    "name": "1 1/4\"",
    "description": "Decreases bat weight by 1.10 oz"
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<CuppedEndRow[]>> = data;

export type CuppedEnd = typeof typed[number];

export const CUPPED_END_INDEX_KEY = "id";
export type CuppedEndIndexKey = "id";
export type CuppedEndId = CuppedEnd["id"];

let i = 0;
export const CUPPED_END_DICT = {
  "none": typed[i++],
  "0_5inch": typed[i++],
  "1inch": typed[i++],
  "1_25inch": typed[i++]
} as const;

export { typed as CUPPED_END };
