// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { KnobTextColorRow } from '../typings';

const data = [
  {
    "id": "black",
    "name": "Black",
    "props": {
      "hex": "#231f20",
      "isMetallic": false
    }
  },
  {
    "id": "white",
    "name": "White",
    "props": {
      "hex": "#ffffff",
      "isMetallic": false
    }
  },
  {
    "id": "gold",
    "name": "Gold",
    "props": {
      "hex": "#ffe677",
      "isMetallic": true
    }
  },
  {
    "id": "silver",
    "name": "Silver",
    "props": {
      "hex": "#fcfcfc",
      "isMetallic": true
    }
  },
  {
    "id": "red",
    "name": "Red",
    "props": {
      "hex": "#ba000b",
      "isMetallic": false
    }
  },
  {
    "id": "royal",
    "name": "Royal",
    "props": {
      "hex": "#005ba1",
      "isMetallic": false
    }
  },
  {
    "id": "coolGray",
    "name": "Cool Gray",
    "props": {
      "hex": "#878a8d",
      "isMetallic": false
    }
  },
  {
    "id": "neonGreen",
    "name": "Neon Green",
    "props": {
      "hex": "#22f600",
      "isMetallic": false
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<KnobTextColorRow[]>> = data;

export type KnobTextColor = typeof typed[number];

export const KNOB_TEXT_COLOR_INDEX_KEY = "id";
export type KnobTextColorIndexKey = "id";
export type KnobTextColorId = KnobTextColor["id"];

let i = 0;
export const KNOB_TEXT_COLOR_DICT = {
  "black": typed[i++],
  "white": typed[i++],
  "gold": typed[i++],
  "silver": typed[i++],
  "red": typed[i++],
  "royal": typed[i++],
  "coolGray": typed[i++],
  "neonGreen": typed[i++]
} as const;

export { typed as KNOB_TEXT_COLOR };
