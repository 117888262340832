import SVG from 'svg.js'
import { each } from '@technically/lodash'

import 'svg.filter.js' // Adds filter

export const EMPTY_PIXEL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg=='

export const expandQuery = (query) =>
  query.replace(/\$([^ .,]+)/g, '[data-name=$1]')

const injectSvg = (container, svgString) => {
  container.svg(svgString)
  let svg
  // work around issue where container.last() throws
  container.each((i, elements) => {
    const element = elements[i]
    if (element.type === 'svg') {
      svg = element
    }
  })
  if (svg == null) {
    throw new Error('Failed to inject svg')
  }
  return svg
}

const hex2rgb = (hexString) => {
  let hex = hexString

  hex = hex.replace(/^#/, '')

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }

  const num = parseInt(hex, 16)

  return {
    r: num >> 16,
    g: (num >> 8) & 255, // eslint-disable-line no-bitwise
    b: num & 255, // eslint-disable-line no-bitwise
  }
}

const colorizeImage = (image, hex) => {
  const rgb = hex2rgb(hex)
  image.filter((add) =>
    add.colorMatrix('matrix', [
      0,
      0,
      0,
      0,
      rgb.r / 255,
      0,
      0,
      0,
      0,
      rgb.g / 255,
      0,
      0,
      0,
      0,
      rgb.b / 255,
      0,
      0,
      0,
      1,
      0,
    ]),
  )
}

export const createImage = (drawing, boxQuery) => {
  drawing.select(expandQuery(boxQuery)).each((i, instances) => {
    const rect = instances[i]
    const image = rect.parent().image(EMPTY_PIXEL)
    image.width(rect.width())
    image.height(rect.height())
    image.x(rect.x())
    image.y(rect.y())
    image.transform(rect.transform())
  })
}

export const createSvgContainer = (drawing, boxQuery) => {
  drawing.select(expandQuery(boxQuery)).each((i, instances) => {
    const rect = instances[i]
    const svgContainer = rect.parent().group()
    svgContainer.attr('data-name', 'svg-container')
  })
}

export const hideChildren = (drawing, query) => {
  const container = drawing.select(expandQuery(query)).first()

  if (!container) {
    return
  }

  container.each((i, instances) => {
    const child = instances[i]
    child.attr('display', 'none')
  })
}

export const fillDeep = (set, fill) => {
  set.fill(fill)
  if (!set.each) return
  set.each((i, instances) => {
    const child = instances[i]
    fillDeep(child, fill)
  })
}

export const updateImage = (drawing, query, imageDataUri, hex) => {
  drawing
    .select(expandQuery(query))
    .attr('display', imageDataUri ? 'block' : 'none')
    .attr('href', imageDataUri || EMPTY_PIXEL, SVG.xlink)
    .each((i, instances) => {
      const image = instances[i]
      if (hex) {
        colorizeImage(image, hex)
      }
    })
}

export const updateSvg = (drawing, query, svgData, fill) => {
  drawing.select(expandQuery(query)).each((i, instances) => {
    const svgContainer = instances[i]

    svgContainer.clear()
    if (svgData == null || svgData === '') {
      return
    }
    const svg = injectSvg(svgContainer, svgData)

    const rect = svgContainer.parent().select('rect').first()

    svg.x(rect.x())
    svg.y(rect.y())
    svg.width(rect.width())
    svg.height(rect.height())
    svg.transform(rect.transform())
    svg.fill(fill || '')
  })
}

export const updateText = (drawing, query, textString, fontFamily, fill) => {
  drawing.select(expandQuery(query)).each((i, children) => {
    const text = children[i]
    const hasContent = textString != null && textString !== ''
    text.attr('display', hasContent ? 'block' : 'none')
    text.plain(textString || '')
    if (fontFamily) {
      text.attr('font-family', fontFamily)
    }
    text.fill(fill || '')
  })
}

const injectContent = (container, svgString) => {
  container.svg(svgString)

  let content = null
  container.each((i, elements) => {
    const element = elements[i]
    if (element.type === 'svg') {
      content = element
    }
  })
  if (content == null) {
    throw new Error('Failed to create svg drawing')
  }
  return content
}

const migrateContent = (source, target) => {
  source.remove()
  source.each((i, children) => {
    children[i].addTo(target)
  })
  each(source.node.attributes, (attr) => {
    target.node.setAttribute(attr.nodeName, attr.nodeValue)
  })
}

export const createSvgDrawing = (svgString) => {
  const dom = document.createElement('div')
  const container = SVG(dom)
  const content = injectContent(container, svgString)
  migrateContent(content, container)

  return container
}

export const svgToBlobUrl = (svgString) => {
  const blob = new Blob([svgString], { type: 'image/svg+xml' })
  const blobUrl = URL.createObjectURL(blob)
  return blobUrl
}

export const debugSvg = (source) => {
  const newWindow = window.open('')
  newWindow.document.writeln(source.svg())
}
