// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { CategoryRow } from '../typings';

const data = [
  {
    "id": "customGameUseBat",
    "name": "Custom Game Use Bat",
    "props": {
      "groupId": "customGameUseBat",
      "isPublished": true
    },
    "description": "Lorem ipsum dolor sit amet, ut pri viderer legimus comprehensam."
  },
  {
    "id": "customFungoBat",
    "name": "Custom Fungo Bat",
    "props": {
      "groupId": "customFungoBat",
      "isPublished": true
    },
    "description": "Lorem ipsum dolor sit amet, ut pri viderer legimus comprehensam."
  },
  {
    "id": "personalizedAwardBat",
    "name": "Personalized Award Bat",
    "props": {
      "groupId": "giftAndAwardBat",
      "isPublished": true
    },
    "description": "Add a special touch by personalizing a message or name."
  },
  {
    "id": "customAwardBat",
    "name": "Custom Award Bat",
    "props": {
      "groupId": "giftAndAwardBat",
      "isPublished": true
    },
    "description": "Full size 34\" bat allows you to customize colors, add logos, and personalize with text."
  },
  {
    "id": "customMiniBat",
    "name": "Custom Mini Bat",
    "props": {
      "groupId": "giftAndAwardBat",
      "isPublished": true
    },
    "description": "Mini 17\" bat allows you to customize colors, add logos, and personalize with text."
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<CategoryRow[]>> = data;

export type Category = typeof typed[number];

export const CATEGORY_INDEX_KEY = "id";
export type CategoryIndexKey = "id";
export type CategoryId = Category["id"];

let i = 0;
export const CATEGORY_DICT = {
  "customGameUseBat": typed[i++],
  "customFungoBat": typed[i++],
  "personalizedAwardBat": typed[i++],
  "customAwardBat": typed[i++],
  "customMiniBat": typed[i++]
} as const;

export { typed as CATEGORY };
