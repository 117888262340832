import { defaultsDeep, flow } from '@technically/lodash'

import {
  OVAL_LOGO_COLOR_DICT,
  STAMP_COLOR_DICT,
  STRIPE_COLOR_DICT,
  STRIPE_SIZE_DICT,
  SURFACE_COLOR_DICT,
  SURFACE_FINISH_DICT,
  SURFACE_FLAME_DICT,
  SURFACE_STYLE_DICT,
  SURFACE_TYPE_DICT,
} from './sheets'

const getSurfaceArea = (surfaceType, surfaceFinish) => ({
  type: surfaceType,
  style: SURFACE_STYLE_DICT[surfaceType.props.surfaceStyleId],
  color:
    surfaceType.props.surfaceColorId &&
    SURFACE_COLOR_DICT[surfaceType.props.surfaceColorId],
  flame:
    surfaceType.props.surfaceFlameId &&
    SURFACE_FLAME_DICT[surfaceType.props.surfaceFlameId],
  finish: surfaceFinish,
})

const expandColorComboValues = (data) => {
  const { colorCombo } = data.colors

  if (!colorCombo) {
    return data
  }

  const colorComboLimitations = colorCombo.limitations

  const surfaceType = SURFACE_TYPE_DICT[colorComboLimitations.surfaceTypeId]
  const stripeColor = STRIPE_COLOR_DICT[colorComboLimitations.stripeColorId]
  const stampColor = STAMP_COLOR_DICT[colorComboLimitations.stampColorId]
  const ovalLogoColor = OVAL_LOGO_COLOR_DICT[colorComboLimitations.stampColorId]
  const stripeSize = STRIPE_SIZE_DICT['1']

  const surfaceArea = getSurfaceArea(surfaceType, SURFACE_FINISH_DICT.matte)

  const handleArea =
    colorComboLimitations.handleSurfaceTypeId ?
      getSurfaceArea(
        SURFACE_TYPE_DICT[colorComboLimitations.handleSurfaceTypeId],
        SURFACE_FINISH_DICT.matte, // Assuming it should be matte.
      )
    : surfaceArea

  return defaultsDeep(
    {
      colors: {
        batColor: null,
        barrel: surfaceArea,
        handle: handleArea,
        stripe: {
          size: stripeSize,
          color: stripeColor,
        },
        ovalLogo: ovalLogoColor,
      },
      decoration: {
        middleSection: {
          color: stampColor,
        },
        endSection: {
          color: stampColor,
        },
      },
    },
    data,
  )
}

const expandBatColorValues = (data) => {
  const surfaceType = data.colors.batColor

  if (!surfaceType) {
    return data
  }

  const surfaceArea = getSurfaceArea(surfaceType, SURFACE_FINISH_DICT.matte)

  return defaultsDeep(
    {
      colors: {
        colorCombo: null,
        barrel: surfaceArea,
        handle: surfaceArea,
      },
    },
    data,
  )
}

const expandTypeValues = (data) =>
  defaultsDeep(
    {
      colors: {
        barrel:
          data.colors.barrel &&
          getSurfaceArea(data.colors.barrel.type, data.colors.barrel.finish),
        handle:
          data.colors.handle &&
          getSurfaceArea(data.colors.handle.type, data.colors.handle.finish),
      },
    },
    data,
  )

export const expandValues = (data) =>
  flow([expandColorComboValues, expandBatColorValues, expandTypeValues])(data)
