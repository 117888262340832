import { forEach } from '@technically/lodash'
import { createRoot } from 'react-dom/client'
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'

import * as controlTreeDebugUtils from '../../../platform/client/control-tree/debugUtils'

import Root from './components/Root'
import store from './store'
import controlTree from './controlTree'
import { STRIPE_COLOR_DICT, SURFACE_COLOR_DICT } from '../common/sheets'

// For debugging.
window.store = store

// Kit uses this for quick'n'dirty previews of colors
window.sheets = {
  stripeColor: STRIPE_COLOR_DICT,
  surfaceColor: SURFACE_COLOR_DICT,
}

// One should be using control-tree/debugUtils via window like window.getNodes().
window.getControlTree = () => controlTree
forEach(controlTreeDebugUtils.createUtils(store, controlTree), (fn, name) => {
  window[name] = fn
})

viewportUnitsBuggyfill.init()

createRoot(document.getElementById('root')).render(<Root store={store} />)
