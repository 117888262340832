import Bluebird from 'bluebird'
import { forEach, fromPairs } from '@technically/lodash'

import SceneLoader from './SceneLoader'
import updateCamera from './updateCamera'

function generatePreviews(
  sceneDef,
  cameraDefs,
  createSceneContext,
  updateScene,
) {
  return (viewNames) => {
    const promise = new Promise((resolve) => {
      const canvas = document.createElement('canvas')

      const sceneContext = createSceneContext(canvas)

      const scene = sceneContext.scene
      const engine = sceneContext.scene.getEngine()
      const camera = sceneContext.scene.activeCamera

      // Not possible to reuse AssetsLoader because Babylon.js assets are linked to specific scene.
      new SceneLoader(scene).load(sceneDef).then((sceneResult) => {
        const queue = []
        forEach(viewNames, (viewName) => {
          queue.push(
            () =>
              new Promise((resolvePreview) => {
                const cameraDef = cameraDefs[viewName]

                const previewSize = cameraDef.previewSize
                if (!previewSize) {
                  throw new Error('previewSize is missing')
                }
                const { width, height } = previewSize

                engine.setSize(width, height)

                updateCamera(camera, cameraDef)

                updateScene(
                  null,
                  { sceneDef },
                  sceneResult,
                  sceneContext,
                  cameraDef,
                )

                scene.executeWhenReady(() => {
                  scene.render()

                  canvas.toBlob((blob) => {
                    resolvePreview([viewName, blob])
                  })
                })
              }),
          )
        })

        Bluebird.mapSeries(queue, (f) => f()).then((pairs) => {
          engine.dispose()

          resolve(fromPairs(pairs))
        })
      })
    })

    return promise
  }
}

export default generatePreviews
